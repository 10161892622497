import { forwardRef, FC } from 'react';

import { IconProps } from '@pxui/components/ui/icons';
import cn from '@pxui/lib/utils';

export interface AppNameProps extends React.HTMLAttributes<HTMLDivElement> {
  appName: string;
  instance?: string | FC<IconProps>;
  prefix: string;
}

export const AppName = forwardRef<HTMLDivElement, AppNameProps>(
  ({ className, prefix, instance: Instance, appName, ...props }, ref) => (
    <div
      className={cn(
        'flex items-center gap-1.5 pl-2 pr-8 pt-2.5 pb-2.5',
        className,
      )}
      ref={ref}
      {...props}
    >
      {Instance && typeof Instance === 'string' && (
        <img
          src={Instance}
          alt={`logo of ${appName} application`}
          className="w-7 h-7"
        />
      )}
      {Instance && typeof Instance !== 'string' && (
        <Instance className="w-7 h-7" />
      )}
      <div className="flex gap-[3px]">
        <span className="text-primary label-1">{prefix}</span>
        <span className="text-primary label-1-strong">{appName}</span>
      </div>
    </div>
  ),
);

AppName.displayName = 'AppName';
