import { type AnchorHTMLAttributes, forwardRef } from 'react';

import cn from '@pxui/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const focusVisibleClasses =
  'focus-visible:outline-none focus-visible:state-focus focus-visible:state-focus-inset focus-visible:border-x-0 focus-visible:border-t-0';

const linkVariants = cva(
  cn(
    'cursor-pointer inline-block border-b border-transparent',
    focusVisibleClasses,
  ),
  {
    defaultVariants: {
      size: 'default',
      variant: 'primary',
    },
    variants: {
      size: {
        default: 'label-1',
      },
      variant: {
        primary: 'text-link-primary hover:text-link-primary-hover',
        secondary: 'text-link-secondary hover:text-link-secondary-hover',
      },
    },
  },
);

interface LinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof linkVariants> {}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, variant, children, ...props }, ref) => (
    <a
      className={cn(linkVariants({ className, variant }))}
      ref={ref}
      {...props}
    >
      {children}
    </a>
  ),
);

Link.displayName = 'Link';

export { Link, type LinkProps, linkVariants };
