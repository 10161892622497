import { forwardRef } from 'react';

import cn from '@pxui/lib/utils';
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import { ChevronRight } from 'lucide-react';

const interactionClasses = 'hover:bg-white/[.08] focus:bg-white/[.08]';

const dataClasses =
  'data-[disabled]:pointer-events-none data-[disabled]:opacity-50 data-[state=open]:bg-white/[.08]';

export const ContextMenuSubTrigger = forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.SubTrigger> & {
    inset?: boolean;
  }
>(({ className, inset, children, ...props }, ref) => (
  <ContextMenuPrimitive.SubTrigger
    ref={ref}
    className={cn(
      'relative min-h-8 p-1.5 flex cursor-default select-none items-center outline-none justify-between label-1 text-white',
      inset && 'pl-8',
      interactionClasses,
      dataClasses,
      className,
    )}
    {...props}
  >
    {children}
    <ChevronRight className="ml-auto h-4 w-4" />
  </ContextMenuPrimitive.SubTrigger>
));
ContextMenuSubTrigger.displayName = ContextMenuPrimitive.SubTrigger.displayName;

export default ContextMenuSubTrigger;
