import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const ArrowLeft: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      d="M11.7114 6.49684V13.5032L8.20826 10L11.7114 6.49684Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowLeft;
