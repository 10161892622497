import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';

import { ContextMenu } from './contextMenu';
import { ContextMenuCheckboxItem } from './contextMenuCheckboxItem';
import { ContextMenuContent } from './contextMenuContent';
import { ContextMenuItem } from './contextMenuItem';
import { ContextMenuLabel } from './contextMenuLabel';
import { ContextMenuRadioItem } from './contextMenuRadioItem';
import { ContextMenuSeparator } from './contextMenuSeparator';
import { ContextMenuShortcut } from './contextMenuShortcut';
import { ContextMenuSubContent } from './contextMenuSubContent';
import { ContextMenuSubTrigger } from './contextMenuSubTrigger';

const ContextMenuTrigger = ContextMenuPrimitive.Trigger;
const ContextMenuGroup = ContextMenuPrimitive.Group;
const ContextMenuPortal = ContextMenuPrimitive.Portal;
const ContextMenuSub = ContextMenuPrimitive.Sub;
const ContextMenuRadioGroup = ContextMenuPrimitive.RadioGroup;

export {
  ContextMenu,
  ContextMenuCheckboxItem,
  ContextMenuContent,
  ContextMenuGroup,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuPortal,
  ContextMenuRadioGroup,
  ContextMenuRadioItem,
  ContextMenuSeparator,
  ContextMenuShortcut,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
};
