import type { SVGProps } from 'react';

import { numbers } from '@design-tokens/primitive-tokens';

const SIZE_XXS = 8;
const SIZE_XS = 10;
const SIZE_SM = 16;
const SIZE_MD = 20;
const SIZE_LG = 24;
const SIZE_XL = 32;

export enum IconSize {
  xxsmall = numbers[SIZE_XXS],
  xsmall = numbers[SIZE_XS],
  small = numbers[SIZE_SM],
  default = numbers[SIZE_MD],
  large = numbers[SIZE_LG],
  xl = numbers[SIZE_XL],
}

export interface IconProps extends SVGProps<SVGElement> {
  id?: string;
  size?: keyof typeof IconSize;
}
