import { ElementRef, forwardRef, useCallback, useState } from 'react';

import { Slider, SliderProps, TextInput } from '@pxui/components/ui';

export const MeshViewerSlider = forwardRef<
  ElementRef<typeof Slider>,
  SliderProps
>((props, ref) => {
  const { min, max, defaultValue, onValueChange } = props;
  const [value, setValue] = useState<number>(defaultValue?.[0] || min || 0);
  const handleValueChange = useCallback(
    (valueChange: number[]) => {
      onValueChange?.(valueChange);
      if (valueChange.length > 0) {
        const [newValue] = valueChange;
        setValue(newValue);
      }
    },
    [onValueChange],
  );
  return (
    <div className="flex flex-row items-center gap-2">
      <div className="flex flex-auto flex-row items-center gap-2">
        {min !== undefined && (
          <span className="label-1 text-secondary">{min}</span>
        )}
        <Slider {...props} ref={ref} onValueChange={handleValueChange} />
        {max !== undefined && (
          <span className="label-1 text-secondary">{max}</span>
        )}
      </div>
      <TextInput
        id="slider-value"
        className="w-10 h-8 px-1"
        disabled
        value={value}
      />
    </div>
  );
});

export default MeshViewerSlider;
