import { MutableRefObject } from 'react';

import { Filters } from '../Filters/Filters';
import { GeometryViewer } from '../GeometryViewer/GeometryViewerFactory';

interface SidebarFiltersProps {
  isLoaded: boolean;
  isSingleMesh?: boolean;
  viewer?: MutableRefObject<GeometryViewer | undefined>;
}

function SidebarFilters({
  isLoaded,
  viewer,
  isSingleMesh,
}: SidebarFiltersProps) {
  return (
    <>
      <div className="label-1-strong text-secondary mb-5">Filters</div>
      {isSingleMesh && <Filters viewer={viewer} isEnabled={isLoaded} />}
    </>
  );
}

export default SidebarFilters;
