import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
} from 'react';

import { cn } from '@pxui/lib/utils';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';

const layoutClasses =
  'top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden';

const dataStateVisibleClasses =
  'data-[state=visible]:animate-in data-[state=visible]:fade-in';

const dataStateHiddenClasses =
  'data-[state=hidden]:animate-out data-[state=hidden]:fade-out';

export const NavigationMenuIndicator = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Indicator>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Indicator
    ref={ref}
    className={cn(
      layoutClasses,
      dataStateVisibleClasses,
      dataStateHiddenClasses,
      className,
    )}
    {...props}
  >
    <div className="relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm bg-slate-200 shadow-md dark:bg-slate-800" />
  </NavigationMenuPrimitive.Indicator>
));

NavigationMenuIndicator.displayName =
  NavigationMenuPrimitive.Indicator.displayName;

export default NavigationMenuIndicator;
