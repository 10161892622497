import { RouteObject } from 'react-router-dom';

import { lazyLoadHelper } from '@utils/lazyLoadHelper';

type OptimizerPage = RouteObject & {
  name: string;
  path: string;
};

export type Pages = {
  [key: string]: OptimizerPage;
};

// TODO:  clean up
const pages: Pages = {
  candidateMash: {
    lazy: lazyLoadHelper(
      () => import('./pages/CandidateMeshPage/CandidateMeshPage'),
    ),
    name: 'Candidate Mesh',
    path: 'candidate-mesh',
  },
  comparison: {
    lazy: lazyLoadHelper(() => import('./pages/ComparisonPage/ComparisonPage')),
    name: 'Comparison',
    path: 'comparison',
  },
  home: {
    index: true,
    lazy: lazyLoadHelper(() => import('./pages/HomePage/HomePage')),
    name: 'Home',
    path: '',
  },
  inspector: {
    lazy: lazyLoadHelper(() => import('./pages/InspectorPage/InspectorPage')),
    name: 'Inspector',
    path: 'inspector',
  },
};

export default pages;
