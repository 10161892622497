import {
  DetailsMain,
  DetailsSection,
  DetailsSectionTitle,
} from '@pxui/components/ui';

import MeshFieldDataTable from './MeshDataTable';

interface SidebarMeshMetadataProps {
  filename: string;
  metadata: string[][];
}

function SidebarMeshMetadata({ filename, metadata }: SidebarMeshMetadataProps) {
  return (
    <DetailsMain>
      <DetailsSection>
        <div>
          <DetailsSectionTitle className="title-3 px-0">
            {filename}
          </DetailsSectionTitle>
          {/* placeholder for path e.g. '/Lethe/From Client/Data/3D/Volumes' */}
        </div>
        <div className="my-4 w-full">
          {metadata && metadata.length > 0 && (
            <MeshFieldDataTable data={metadata} />
          )}
        </div>
      </DetailsSection>
    </DetailsMain>
  );
}

export default SidebarMeshMetadata;
