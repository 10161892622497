import type { FC, HTMLAttributes, ReactNode } from 'react';

import { cn } from '@pxui/lib/utils';

interface TagProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  value?: string | ReactNode;
}

const Tag: FC<TagProps> = ({ className, label, value, ...props }) => (
  <div
    className={cn(
      'inline-flex items-center justify-center text-secondary code-1 rounded-sm border border-tag-gray bg-tag-gray transition-colors overflow-hidden h-6',
      className,
    )}
    {...props}
  >
    <div className="text-secondary code-1 rounded-sm bg-tag-gray px-1.5 py-1 h-6">
      {label}
    </div>

    {value && (
      <div className="surface-01 text-primary pl-[5px] pr-1.5 py-1 h-6">
        {value}
      </div>
    )}
  </div>
);

Tag.displayName = 'Tag';

export { Tag, type TagProps };
