import lazyLoadHelper from '@utils/lazyLoadHelper';

import optimizerImageSmall from '@assets/images/apps/optimizer_small.png';
import optimizerImage from '@assets/images/launcher/optimizer.png';

import { type AppConfig } from '@typing/AppConfig';

import { routes } from './routes';

export const config: AppConfig = {
  appName: 'Optimizer',
  description: 'Inspect Optimisation runs and results',
  icon: optimizerImageSmall,
  id: 'optimizer',
  imageSrc: optimizerImage,
  navigationLinks: [
    {
      label: 'Home',
      path: '/app/optimizer',
    },
    // {
    //   label: 'Inspector',
    //   path: '/app/optimizer/optimization/inspector',
    // },
  ],
  path: 'app/optimizer',
  root: lazyLoadHelper(() => import('@optimizer/pages/index')),
  routes,
};

export default config;
