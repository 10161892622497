import type { FC } from 'react';

interface HelperTextProps {
  helperText?: string;
}

const HelperText: FC<HelperTextProps> = ({ helperText }) => {
  if (!helperText) return null;

  return (
    <span className="label-2 text-secondary peer-disabled:text-disabled">
      {helperText}
    </span>
  );
};

export { HelperText, type HelperTextProps };
