import { HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

export const CommandFooter = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children }, ref) => (
  <div
    className={cn(
      'flex items-center border-01-subtle border-t px-8 gap-6 label-1 text-secondary',
      className,
    )}
    ref={ref}
  >
    {children}
  </div>
));

CommandFooter.displayName = 'CommandFooter';

export default CommandFooter;
