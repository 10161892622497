import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const TextFieldsAlt: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2 16.6794V15.5H18V16.6794H2ZM16 14V3.41667H16.7435V14H16ZM3.99208 14L8.12896 3.41667H9.45021L13.6121 14H12.1923L11.1308 11.1796H6.43104L5.35896 14H3.99208ZM6.85729 10H10.7019L8.80437 5.04167H8.75792L6.85729 10Z"
      fill="currentColor"
    />
  </svg>
);

export default TextFieldsAlt;
