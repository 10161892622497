import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  children: React.ReactElement;
}

/**
 * AuthenticationGuard component that ensures the user is authenticated before rendering its children.
 * If the user is not authenticated, it triggers a redirect to the login page.
 *
 * @param {Props} props - The properties object.
 * @param {React.ReactElement} props.children - The child components to render if authenticated.
 *
 * @returns {React.ReactElement | null} The child components if authenticated, otherwise null.
 */
export const AuthenticationGuard: React.FC<Props> = ({ children }) => {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading === false && isAuthenticated === false && !error) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, error]);

  return isAuthenticated && !error && children;
};

export default AuthenticationGuard;
