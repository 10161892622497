import type { FC } from 'react';

import { Spinner } from '@pxui/components/ui';
import cn from '@pxui/lib/utils';

const layoutClasses = 'absolute w-full h-full flex items-center justify-center';
const colorClasses = 'surface-01 bg-opacity-60';

export const DetailsLoader: FC = () => (
  <div className={cn(layoutClasses, colorClasses)}>
    <Spinner />
  </div>
);

export default DetailsLoader;
