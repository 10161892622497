export enum ProgressVariant {
  Active = 'active',
  Error = 'error',
  Success = 'success',
}

export type DetermineProgressStatusFunction = (
  value: number,
  error?: boolean,
) => { indicatorLabel: string; variant: ProgressVariant };

export default ProgressVariant;
