import type { FC, HTMLAttributes } from 'react';

import { cn } from '@pxui/lib/utils';

const layoutClasses = 'flex flex-col items-stretch w-full';
const spacingClasses = 'py-1';

export const ListSection: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <section className={cn(layoutClasses, spacingClasses, className)} {...props}>
    {children}
  </section>
);

export default ListSection;
