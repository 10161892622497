import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';
import { Close } from '@radix-ui/react-toast';
import { X } from 'lucide-react';

export const ToastCancel = forwardRef<
  ElementRef<typeof Close>,
  ComponentPropsWithoutRef<typeof Close>
>(({ className, ...props }, ref) => (
  <button
    type="button"
    ref={ref}
    className={cn(
      'absolute right-1 top-1 rounded-md p-1 text-gray-300 opacity-0 transition-opacity hover:text-red-50 focus:ring-red-400 focus:ring-offset-red-600 focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100',
      className,
    )}
    {...props}
  >
    <X className="h-4 w-4" />
  </button>
));

ToastCancel.displayName = 'ToastCancel';

export default ToastCancel;
