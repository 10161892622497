import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Download: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.99984 12.8237L6.6088 9.4327L7.38296 8.67478L9.45817 10.75V3.41666H10.5415V10.75L12.6167 8.67478L13.3909 9.4327L9.99984 12.8237ZM5.75046 15.5833C5.37505 15.5833 5.05887 15.4538 4.80192 15.1948C4.54498 14.9358 4.4165 14.6187 4.4165 14.2435V12.9839H5.49984V14.2435C5.49984 14.3077 5.52657 14.3664 5.58005 14.4198C5.63338 14.4733 5.69213 14.5 5.7563 14.5H14.2434C14.3075 14.5 14.3663 14.4733 14.4196 14.4198C14.4731 14.3664 14.4998 14.3077 14.4998 14.2435V12.9839H15.5832V14.2435C15.5832 14.6187 15.4536 14.9358 15.1944 15.1948C14.9353 15.4538 14.618 15.5833 14.2425 15.5833H5.75046Z"
      fill="currentColor"
    />
  </svg>
);

export default Download;
