import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Search: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.8894 16.6475L10.9263 11.6842C10.5096 12.0004 10.0527 12.2453 9.55563 12.419C9.05855 12.5926 8.54556 12.6794 8.01667 12.6794C6.71695 12.6794 5.61202 12.2244 4.70188 11.3146C3.79174 10.4047 3.33667 9.30027 3.33667 8.00124C3.33667 6.70235 3.7916 5.59749 4.70146 4.68666C5.61132 3.77597 6.71577 3.32062 8.01479 3.32062C9.31368 3.32062 10.4185 3.77569 11.3294 4.68583C12.2401 5.59596 12.6954 6.7009 12.6954 8.00062C12.6954 8.54548 12.606 9.06645 12.4271 9.56353C12.2481 10.0608 12.0058 10.5096 11.7002 10.9102L16.6633 15.8733L15.8894 16.6475ZM8.01604 11.5962C9.02035 11.5962 9.87077 11.2479 10.5673 10.5512C11.264 9.85471 11.6123 9.0043 11.6123 7.99999C11.6123 6.99569 11.264 6.14527 10.5673 5.44874C9.87077 4.75208 9.02035 4.40374 8.01604 4.40374C7.01174 4.40374 6.16132 4.75208 5.46479 5.44874C4.76813 6.14527 4.41979 6.99569 4.41979 7.99999C4.41979 9.0043 4.76813 9.85471 5.46479 10.5512C6.16132 11.2479 7.01174 11.5962 8.01604 11.5962Z"
      fill="currentColor"
    />
  </svg>
);

export default Search;
