import { type HTMLAttributes, forwardRef } from 'react';

import cn from '@pxui/lib/utils';

export const TileFooter = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex flex-col row-start-4', className)}
    {...props}
  />
));

TileFooter.displayName = 'TileFooter';

export default TileFooter;
