import { type HTMLAttributes, forwardRef } from 'react';

export interface TableHeaderSectionProps
  extends HTMLAttributes<HTMLDivElement> {
  title?: string;
}

export const TableHeaderSection = forwardRef<
  HTMLDivElement,
  TableHeaderSectionProps
>(({ title, children, ...props }, ref) => {
  return (
    <div ref={ref} className="flex items-center gap-8" {...props}>
      {title && <h1 className="title-3 text-primary">{title}</h1>}
      {children}
    </div>
  );
});
TableHeaderSection.displayName = 'TableHeaderSection';

export default TableHeaderSection;
