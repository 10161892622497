import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Neutral: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <rect x="8" y="8" width="4" height="4" fill="currentColor" />
  </svg>
);

export default Neutral;
