import { atom } from 'recoil';

export interface TermsAndConditions {
  currentVersion: number;
  fileName: string;
  prevAcceptedVersion: number | null;
  url: string;
}

export const termsAndConditionsState = atom<TermsAndConditions | null>({
  default: null,
  key: 'termsAndConditionsFamily',
});
