import { type HTMLAttributes, forwardRef } from 'react';

import { Link } from '@pxui/components/ui/link';
import { cn } from '@pxui/lib/utils';

interface DetailsSectionTitleProps extends HTMLAttributes<HTMLDivElement> {
  linkLabel?: string;
  linkTo?: string;
}

const containerLayoutClasses = 'flex items-start';
const containerSpacingClasses = 'py-[7px] px-4';

const textLayoutClasses = 'label-1-strong';
const textColorClasses = 'text-primary';

const DetailsSectionTitle = forwardRef<
  HTMLDivElement,
  DetailsSectionTitleProps
>(({ className, children, linkLabel, linkTo, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(containerLayoutClasses, containerSpacingClasses, className)}
    {...props}
  >
    <span className={cn(textLayoutClasses, textColorClasses)}>{children}</span>

    {linkLabel && <Link href={linkTo}>{linkLabel}</Link>}
  </div>
));

DetailsSectionTitle.displayName = 'DetailsSectionTitle';

export { DetailsSectionTitle, type DetailsSectionTitleProps };
