import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

// after classes define the bottom border of sticky header that actually sticks together with header on scroll
const stickyHeaderClasses =
  'sticky top-0 z-10 after:content-[""] after:absolute after:left-0 after:bottom-0 after:w-full after:h-[1px] after:bg-surface-01';

export const TableTHead = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn(stickyHeaderClasses, className)} {...props} />
));

TableTHead.displayName = 'TableTHead';

export default TableTHead;
