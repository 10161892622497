import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuTrigger,
  type DropdownProps,
} from './dropdown';
import { DropdownMenuCheckboxItem } from './dropdownMenuCheckboxItem';
import { DropdownMenuContent } from './dropdownMenuContent';
import { DropdownMenuItem } from './dropdownMenuItem';
import { DropdownMenuLabel } from './dropdownMenuLabel';
import { DropdownMenuRadioItem } from './dropdownMenuRadioItem';
import { DropdownMenuSeparator } from './dropdownMenuSeparator';
import { DropdownMenuShortcut } from './dropdownMenuShortcut';
import { DropdownMenuSubContent } from './dropdownMenuSubContent';
import { DropdownMenuSubTrigger } from './dropdownMenuSubTrigger';
import {
  DropdownMenuTriggerButton,
  type DropdownMenuTriggerButtonProps,
} from './dropdownMenuTriggerButton';

const DropdownMenuGroup = DropdownMenuPrimitive.Group;
const DropdownMenuPortal = DropdownMenuPrimitive.Portal;
const DropdownMenuSub = DropdownMenuPrimitive.Sub;
const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

export {
  Dropdown,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuTriggerButton,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuRadioGroup,
  type DropdownProps,
  type DropdownMenuTriggerButtonProps,
};
