import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const ArrowDropUp: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      d="M6.49707 11.7916L10.0002 8.28845L13.5034 11.7916H6.49707Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowDropUp;
