import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const FluxLogo: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M19.471 12.3115V0H7.15948C7.15948 6.80209 12.6719 12.3115 19.471 12.3115Z"
      fill="white"
    />
    <path
      d="M19.471 40V27.6885H7.15948C7.15948 34.4906 12.6719 40 19.471 40Z"
      fill="white"
    />
    <path
      d="M20.9824 0V12.3115H33.2938C33.2938 5.51246 27.7845 0 20.9824 0Z"
      fill="white"
    />
    <path
      d="M20.9824 26.1373V13.8258H33.2938C33.2938 20.6248 27.7845 26.1373 20.9824 26.1373Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4798 15.4996L15.023 19.9564L13.2819 18.2153L17.7387 13.7585L19.4798 15.4996ZM8.74111 13.7647L13.1979 18.2215L11.4568 19.9626L7 15.5058L8.74111 13.7647ZM7 24.5249L11.4568 20.0682L13.1979 21.8093L8.74111 26.266L7 24.5249ZM17.7387 26.2691L13.2819 21.8124L15.023 20.0713L19.4798 24.528L17.7387 26.2691Z"
      fill="#0AFFB0"
    />
  </svg>
);

export default FluxLogo;
