import * as React from 'react';

import { cn } from '@pxui/lib/utils';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { cva, VariantProps } from 'class-variance-authority';

// Define the track styles
const trackVariants = cva(
  'relative h-2 w-full grow overflow-hidden rounded-full',
  {
    defaultVariants: {
      size: 'medium',
      state: 'normal',
    },
    variants: {
      size: {
        medium: 'h-2',
        small: 'h-1',
      },
      state: {
        active: 'bg-[hsla(218,7%,21%,1)]',
        disabled: 'bg-[hsla(218,7%,21%,1)]',
        normal: 'bg-[hsla(218,7%,21%,1)]',
      },
    },
  },
);

// Define the range styles
const rangeVariants = cva('absolute h-full', {
  defaultVariants: {
    state: 'normal',
  },
  variants: {
    state: {
      active: 'bg-blue-600',
      disabled: 'bg-[hsla(231,4%,68%,1)]',
      normal: 'bg-blue-600', // Range color for the normal state
    },
  },
});

// Define the thumb styles
const thumbVariants = cva(
  'block rounded-full transition-colors focus-visible:outline-none focus-visible:ring-2 disabled:pointer-events-none disabled:opacity-50',
  {
    defaultVariants: {
      size: 'medium',
      state: 'normal',
    },
    variants: {
      size: {
        medium: 'h-5 w-5', // 20px diameter
        small: 'h-4 w-4',
      },
      state: {
        active: 'bg-blue-600 shadow-inner slider-thumb-inner-shadow',
        disabled:
          'border-gray-500 bg-gray-500 dark:border-gray-700 dark:bg-gray-700',
        normal: 'bg-blue-600 shadow-inner slider-thumb-inner-shadow', // Custom shadow for the normal state
      },
    },
  },
);

// Track component with range
const SliderTrack: React.FC<
  VariantProps<typeof trackVariants> & VariantProps<typeof rangeVariants>
> = ({ size, state }) => (
  <SliderPrimitive.Track className={cn(trackVariants({ size, state }))}>
    <SliderPrimitive.Range className={cn(rangeVariants({ state }))} />
  </SliderPrimitive.Track>
);
// Thumb component
const SliderThumb: React.FC<VariantProps<typeof thumbVariants>> = ({
  size,
  state,
}) =>
  state !== 'disabled' ? (
    <SliderPrimitive.Thumb className={cn(thumbVariants({ size, state }))} />
  ) : null;

type SliderProps = React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> &
  VariantProps<typeof trackVariants> &
  VariantProps<typeof thumbVariants>;
// Main Slider component
const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  SliderProps
>(({ className, size, state, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex w-full touch-none select-none items-center',
      className,
    )}
    {...props}
    disabled={state === 'disabled'}
  >
    <SliderTrack size={size} state={state} />
    <SliderThumb size={size} state={state} />
  </SliderPrimitive.Root>
));

Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider, type SliderProps, SliderTrack, SliderThumb };
