import { FC } from 'react';

import { IconProps, IconSize } from '@pxui/components/ui';
import { cn } from '@pxui/lib/utils';

export type PlanetProps = {
  delay?: boolean;
  direction?: 'left' | 'right';
  distance: number;
  icon: FC<IconProps>;
  id?: number;
  size?: keyof typeof IconSize;
};

export const Planet = ({
  delay,
  distance,
  direction,
  icon: Icon,
  id,
  size,
}: PlanetProps) => {
  const halfDistance = distance / 2;

  return (
    <div className="absolute">
      <div
        className="relative"
        style={{
          height: `${distance * 2}px`,
          width: `${distance * 2}px`,
        }}
      >
        <div className="absolute top-1/2 left-1/2 w-0 h-0">
          <div
            className={cn('absolute animate-spin [animation-duration:80s]', {
              '[animation-delay:-15s]': delay,
              '[animation-direction:reverse]': direction === 'right',
            })}
            style={{
              height: `${distance}px`,
              left: `calc(-${halfDistance}px)`,
              top: `calc(-${halfDistance}px)`,
              width: `${distance}px`,
            }}
          >
            <div className="w-full h-full">
              <div
                className={cn(
                  'inline-block p-1 rounded bg-surface-container on-surface-subtle animate-spin [animation-duration:80s] [animation-direction:reverse]',
                  {
                    '[animation-delay:-15s]': delay,
                    '[animation-direction:normal]': direction === 'right',
                  },
                )}
              >
                <Icon size={size} key={id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Planet;
