import { type HTMLAttributes, forwardRef } from 'react';

import cn from '@pxui/lib/utils';

export const TileSubtitle = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h4
    ref={ref}
    className={cn('p-3 text-primary title-2 line-clamp-2', className)}
    style={{ wordBreak: 'break-word' }}
    {...props}
  >
    {children}
  </h4>
));

TileSubtitle.displayName = 'TileSubtitle';

export default TileSubtitle;
