import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Warning: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.64111 16.7171L10.9199 2.9671L19.1986 16.7171H2.64111ZM4.5657 15.6338H17.274L10.9199 5.05044L4.5657 15.6338ZM10.9155 14.4736C11.0813 14.4736 11.2218 14.4174 11.3369 14.3052C11.4521 14.193 11.5097 14.054 11.5097 13.8881C11.5097 13.7223 11.4535 13.5818 11.3413 13.4667C11.2291 13.3515 11.0901 13.294 10.9242 13.294C10.7584 13.294 10.6179 13.3501 10.5028 13.4623C10.3876 13.5745 10.3301 13.7136 10.3301 13.8794C10.3301 14.0452 10.3862 14.1857 10.4984 14.3009C10.6106 14.416 10.7497 14.4736 10.9155 14.4736ZM10.3782 12.294H11.4615V8.29398H10.3782V12.294Z"
      fill="currentColor"
    />
  </svg>
);

export default Warning;
