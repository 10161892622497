import { useAuth0 } from '@auth0/auth0-react';

import termsConditionsMarkAccepted from '@services/termsConditionsMarkAccepted';

import {
  HTTP_STATUS_BAD_REQUEST,
  HTTP_STATUS_OK,
} from '@constants/httpStatusCodes';

const useTermsConditionsMarkAccepted = () => {
  const { getAccessTokenSilently } = useAuth0();
  const markAccepted = async (
    version: number,
  ): Promise<{ isError?: boolean } | undefined> => {
    try {
      const response = await termsConditionsMarkAccepted(version);
      if (
        response.status >= HTTP_STATUS_OK &&
        response.status < HTTP_STATUS_BAD_REQUEST
      ) {
        await getAccessTokenSilently({ cacheMode: 'off' });
        return response.data;
      }
    } catch (error: any) {
      console.error('Error fetching Terms and Conditions:', error);
      return {
        isError: true,
      };
    }
  };

  return { markAccepted };
};

export default useTermsConditionsMarkAccepted;
