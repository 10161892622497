import { FC, useCallback, useState } from 'react';

import { Button, Dialog, Spinner } from '@pxui/components/ui';
import { toast } from '@pxui/components/ui/toast/useToast';
import cn from '@pxui/lib/utils';
import {
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@radix-ui/react-dialog';
import { useRecoilState } from 'recoil';

import useTermsConditionsMarkAccepted from '@hooks/useTermsConditionsMarkAccepted';
import {
  TermsAndConditions,
  termsAndConditionsState,
} from '@states/latestTermsAndConditions';

const sizeClasses = 'max-w-[312px] max-h-[800px]';
const spacingClasses = 'p-8 z-10 m-auto gap-4 flex flex-col';

interface TermsAndConditionsModalProps {
  isError?: boolean;
  isLoading?: boolean;
  open: boolean;
  setAccepted: (newValue: boolean) => void;
}

const ErrorContent = () => {
  return (
    <>
      <DialogTitle className="title-3 text-on-surface py-1">
        There has been an error loading Terms and Conditions
      </DialogTitle>
      <DialogDescription className="paragraph-1 text-on-surface">
        Try again and contact us if the issue persists.
      </DialogDescription>
    </>
  );
};

const ModalContent = ({
  termsAndConditions,
  handleAcceptTerms,
  isLoading,
}: {
  handleAcceptTerms: () => void;
  isLoading?: boolean;
  termsAndConditions: TermsAndConditions;
}) => {
  const prevAcceptedTCVersion = termsAndConditions?.prevAcceptedVersion;
  const isNotLatest =
    prevAcceptedTCVersion &&
    prevAcceptedTCVersion < termsAndConditions.currentVersion;
  const prevAcceptedMessage = `You have previously accepted v${prevAcceptedTCVersion} of our Terms and conditions which has now been updated.`;
  return (
    <>
      <DialogTitle className="title-3 text-on-surface py-1">
        Terms and Conditions
      </DialogTitle>
      <div className="py-1">
        <DialogDescription className="paragraph-1 text-on-surface pb-4">
          {`${isNotLatest ? prevAcceptedMessage : ''} To proceed, please accept ${isNotLatest ? 'the latest' : 'our'}`}
          <a
            href={termsAndConditions?.url}
            download={
              termsAndConditions?.fileName ??
              'PhysicsX End User Licence Agreement.pdf'
            }
            rel="noreferrer"
            className="text-blue-450 underline pl-1 paragraph-1"
          >
            Terms and Conditions
          </a>
          .
        </DialogDescription>
        <DialogDescription className="paragraph-1 text-on-surface">
          It’s important that you read and understand them before continuing to
          use our services.
        </DialogDescription>
      </div>
      <div className="flex flex-row justify-end items-center">
        <Button
          layout="textOnly"
          onClick={handleAcceptTerms}
          className="pt-2 w-[73px]"
          disabled={isLoading}
        >
          {isLoading ? <Spinner /> : `I accept`}
        </Button>
      </div>
    </>
  );
};

const TermsAndConditionsModal: FC<TermsAndConditionsModalProps> = ({
  setAccepted,
  open,
  isLoading,
  isError,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [termsAndConditions] = useRecoilState(termsAndConditionsState);
  const { markAccepted } = useTermsConditionsMarkAccepted();

  const handleAcceptTerms = useCallback(async () => {
    setIsButtonLoading(true);
    const response = await markAccepted(
      termsAndConditions?.currentVersion ?? 0,
    );
    if (response?.isError) {
      toast({
        description:
          'There has been an error in saving your submission. Please try again or contact us if the issue persists',
        title: 'Acceptance error',
        variant: 'error',
      });
      return;
    }
    // TODO: remove once we have updates the refresh token logic to be faster
    setTimeout(() => {
      setAccepted(true);
      setIsButtonLoading(false);
      // eslint-disable-next-line no-magic-numbers
    }, 1000);
  }, [termsAndConditions, markAccepted, setAccepted]);

  return (
    <div className="w-full h-full fixed top-0 left-0 z-10 p-8 flex flex-col justify-center items-center state-active backdrop-blur-[1px]">
      {!isLoading ? (
        <Dialog open={open}>
          <DialogContent
            className={cn(
              sizeClasses,
              spacingClasses,
              'overflow-hidden bg-surface-01 rounded-lg',
            )}
            data-testid="terms-and-conditions-dialog"
          >
            {!isError && termsAndConditions ? (
              <ModalContent
                termsAndConditions={termsAndConditions}
                handleAcceptTerms={handleAcceptTerms}
                isLoading={isButtonLoading}
              />
            ) : (
              <ErrorContent />
            )}
          </DialogContent>
        </Dialog>
      ) : (
        <div className=" absolute inset-0 z-20 flex items-center justify-center surface-01 bg-opacity-50">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default TermsAndConditionsModal;
