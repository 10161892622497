import type { FC } from 'react';

import { cn } from '@pxui/lib/utils';

import spinner from './spinner.png';

interface SpinnerProps {
  className?: string;
}

const Spinner: FC<SpinnerProps> = ({ className }) => (
  <img
    alt="loader"
    src={spinner}
    className={cn('rounded-full animate-spin w-5 h-5', className)}
  />
);

export { Spinner, type SpinnerProps };
