import { type ReactNode, forwardRef } from 'react';

import cn from '@pxui/lib/utils';

interface SectionHeaderProps {
  children: ReactNode;
  className?: string;
}

const layoutClasses = 'flex justify-between items-center';
const spacingClasses = 'px-4 py-2';

const SectionHeader = forwardRef<HTMLDivElement, SectionHeaderProps>(
  ({ children, className }, ref) => (
    <div className={cn(layoutClasses, spacingClasses, className)} ref={ref}>
      {children}
    </div>
  ),
);

SectionHeader.displayName = 'SectionHeader';

export { SectionHeader, type SectionHeaderProps };
