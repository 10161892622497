import { type HTMLAttributes, forwardRef } from 'react';

import cn from '@pxui/lib/utils';

export const TileText = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('text-secondary paragraph-1 p-3', className)}
    {...props}
  />
));

TileText.displayName = 'TileText';

export default TileText;
