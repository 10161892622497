import {
  type ButtonHTMLAttributes,
  type FunctionComponent,
  forwardRef,
} from 'react';

import { IconProps, IconSize } from '@pxui/components/ui/icons';
import { cn } from '@pxui/lib/utils';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

const layoutClasses =
  'group items-center flex rounded items-center justify-center';

const spacingClasses = 'py-1.5 gap-1.5';

const focusClasses =
  'focus-visible:state-focus focus-visible:state-focus-inset';

const disabledClasses = 'disabled:pointer-events-none';

const buttonVariants = cva(
  cn(
    'label-1 text-primary whitespace-nowrap transition-colors',
    layoutClasses,
    spacingClasses,
    focusClasses,
    disabledClasses,
  ),
  {
    defaultVariants: {
      variant: 'primary',
    },
    variants: {
      grow: {
        false: '',
        true: 'w-full',
      },
      layout: {
        iconOnly: 'p-1.5',
        iconText: 'flex-row pl-1.5 pr-3',
        textIcon: 'flex-row-reverse pl-3 pr-1.5',
        textOnly: 'px-3',
      },
      variant: {
        danger:
          'bg-error-container text-primary active:bg-button-danger-active disabled:button-disabled hover:bg-button-danger-hover elevation-01 active:elevation-00',
        ghost:
          'button-ghost active:state-active hover:state-hover border border-transparent',
        primary:
          'bg-button-primary text-primary active:bg-button-primary-active disabled:button-disabled hover:bg-button-primary-hover elevation-01 active:elevation-00',
        secondary:
          'bg-button-secondary text-primary active:bg-button-secondary-active disabled:button-disabled hover:bg-button-secondary-hover elevation-01 active:elevation-00',
      },
    },
  },
);

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  children?: React.ReactNode;
  grow?: boolean;
  icon?: FunctionComponent<IconProps>;
  iconSize?: keyof typeof IconSize;
  layout: 'textOnly' | 'iconText' | 'textIcon' | 'iconOnly';
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild = false,
      children,
      className,
      grow = false,
      icon: Icon,
      layout,
      type = 'button',
      variant,
      iconSize,
      ...props
    },
    ref,
  ) => {
    const classes = cn(buttonVariants({ className, grow, layout, variant }));
    const icon = layout !== 'textOnly' && Icon && (
      <Icon
        className="fill-icon-primary group-disabled:text-icon-disabled group-disabled:fill-icon-disabled transition-colors"
        size={iconSize}
      />
    );

    if (asChild) {
      return (
        <Slot className={classes} ref={ref} {...props}>
          {icon}
          <Slottable>{children}</Slottable>
        </Slot>
      );
    }

    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={classes}
        ref={ref}
        {...props}
      >
        {icon}
        {layout !== 'iconOnly' && children}
      </button>
    );
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
