import { type FC, type HTMLAttributes, forwardRef } from 'react';

import { IconProps, IconSize } from '@pxui/components/ui/icons';
import { TileText } from '@pxui/components/ui/tile';
import cn from '@pxui/lib/utils';

interface TileIconTextProps extends HTMLAttributes<HTMLParagraphElement> {
  icon: FC<IconProps>;
  iconSize?: keyof typeof IconSize;
  text: string;
}

const TileIconText = forwardRef<HTMLParagraphElement, TileIconTextProps>(
  ({ className, icon: Icon, iconSize, text, ...props }, ref) => (
    <TileText
      ref={ref}
      className={cn('flex gap-3 items-center', className)}
      {...props}
    >
      <Icon size={iconSize} />
      <span>{text}</span>
    </TileText>
  ),
);

TileIconText.displayName = 'TileIconText';

export { TileIconText, type TileIconTextProps };
