import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

const containerLayoutClasses = 'flex w-full flex-col';
const containerSpacingClasses = 'py-1 px-4';

const textLayoutClasses = 'title-3 break-words';
const textColorClasses = 'text-primary';

export const DetailsTitle = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(containerLayoutClasses, containerSpacingClasses, className)}
    {...props}
  >
    <span className={cn(textLayoutClasses, textColorClasses)}>{children}</span>
  </div>
));

DetailsTitle.displayName = 'DetailsTitle';

export default DetailsTitle;
