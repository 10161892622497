import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@pxui/components/ui/tooltip';
import cn from '@pxui/lib/utils';

import type { TooltipContentProps } from '@radix-ui/react-tooltip';

export interface InfoTooltipProps {
  children: React.ReactNode;
  className?: string;
  description: string;
  side?: TooltipContentProps['side'];
}

const tooltipClasses = 'p-1';

export const InfoTooltip = ({
  children,
  className,
  description,
  side = 'right',
}: InfoTooltipProps) => {
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent className={cn(tooltipClasses, className)} side={side}>
          {description}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default InfoTooltip;
