import { PropsWithChildren } from 'react';

import { Auth0Context } from '@auth0/auth0-react';
import { Toaster } from '@pxui/components/ui/toast';

import { FullScreenContainer, Navbar } from '@components/index';

import { AppbarContext } from '@contexts/AppbarContext';

import { type AppbarState } from '@typing/AppbarState';

type Props = {
  appBarState: AppbarState;
} & PropsWithChildren;

export const defaultAuth0Context = {
  buildAuthorizeUrl: (): any => null,
  buildLogoutUrl: (): any => null,
  getAccessTokenSilently: (): any => 'mocked token',
  getAccessTokenWithPopup: (): any => null,
  getIdTokenClaims: (): any => null,
  handleRedirectCallback: (): any => null,
  isAuthenticated: true,
  isLoading: false,
  loginWithPopup: (): any => null,
  loginWithRedirect: (): any => null,
  logout: (): any => null,
  user: {
    name: 'test@example.com',
  },
};

export const ScreenWrapper = ({ appBarState, children }: Props) => (
  <Auth0Context.Provider value={defaultAuth0Context}>
    <AppbarContext.Provider value={appBarState}>
      <FullScreenContainer>
        <Navbar />
        {children}
        <Toaster />
      </FullScreenContainer>
    </AppbarContext.Provider>
  </Auth0Context.Provider>
);

export default ScreenWrapper;
