import apiClient from '@services/interceptors/authInterceptor';

const fetchLatestFluxCLI = async () => {
  const response = await apiClient.get(
    `${process.env.PLATFORM_APP_MANAGEMENT_API}/v0/packages/cli/download?version=latest`,
    {
      responseType: 'blob',
    },
  );

  return response;
};

export default fetchLatestFluxCLI;
