import { useCallback, useEffect, useState, type FC } from 'react';

import cn from '@pxui/lib/utils';
import {
  type ResizableProps,
  Resizable,
  type ResizeCallback,
} from 're-resizable';

interface DetailsResizableWrapperProps extends ResizableProps {
  minWidth?: number;
  open?: boolean;
}

const MIN_CARD_WIDTH = 296;
const MAX_WIDTH_PERCENTAGE = 48;

const transitionClasses =
  'transition-[width,min-width,opacity,padding-right,margin-left] duration-300';

const stateOpenClasses = 'pr-4 ml-4 opacity-100';
const stateClosedClasses = 'opacity-0';

const layoutClasses = 'overflow-hidden !sticky top-0 right-0';
const spacingClasses = 'py-4';

const DetailsResizableWrapper: FC<DetailsResizableWrapperProps> = ({
  className,
  open,
  minWidth = MIN_CARD_WIDTH,
  ...props
}) => {
  const [width, setWidth] = useState(0);
  const [isTransitionOn, setIsTransitionOn] = useState(true);

  useEffect(() => {
    if (open) {
      setWidth(MIN_CARD_WIDTH);
    } else {
      setWidth(0);
    }
  }, [open]);

  const updateWidth = useCallback(() => {
    const maxWidth = window.innerWidth * (MAX_WIDTH_PERCENTAGE / 100);
    if (width > maxWidth) {
      setWidth(maxWidth);
    }
  }, [width]);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [updateWidth]);

  const handleResizeStop: ResizeCallback = (_e, _direction, _ref, d) => {
    setWidth((prev) => prev + d.width);
    setIsTransitionOn(true);
  };

  const handleResizeStart = () => {
    setIsTransitionOn(false);
  };

  return (
    <Resizable
      className={cn(layoutClasses, spacingClasses, {
        className,
        [stateClosedClasses]: !open,
        [stateOpenClasses]: open,
        [transitionClasses]: isTransitionOn,
      })}
      minHeight="calc(100vh - 50px)"
      maxHeight="calc(100vh - 50px)"
      minWidth={open ? minWidth : 0}
      maxWidth={`${MAX_WIDTH_PERCENTAGE}%`}
      size={{
        height: 'calc(100vh - 50px)',
        width: `${width}px`,
      }}
      onResizeStop={handleResizeStop}
      onResizeStart={handleResizeStart}
      enable={{
        bottom: false,
        bottomLeft: false,
        bottomRight: false,
        left: true,
        right: false,
        top: false,
        topLeft: false,
        topRight: false,
      }}
      {...props}
    />
  );
};

export default DetailsResizableWrapper;
