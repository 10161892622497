import { type ButtonHTMLAttributes, type ReactNode, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  selected?: boolean;
}

const layoutClasses = 'inline-flex rounded border border-transparent';
const spacingClasses = 'py-[7px] px-3';
const focusVisibleClasses =
  'focus-visible:outline-none focus-visible:state-focus focus-visible:state-focus-inset';

const Tab = forwardRef<HTMLButtonElement, TabProps>(
  ({ className, children, selected }, ref) => {
    const tabClasses = cn(
      'label-1 text-secondary transition-colors cursor-pointer',
      selected ? 'bg-state-selected' : 'active:state-active hover:state-hover',
      layoutClasses,
      spacingClasses,
      focusVisibleClasses,
      className,
    );

    return (
      <button type="button" role="tab" className={tabClasses} ref={ref}>
        {children}
      </button>
    );
  },
);

Tab.displayName = 'Tab';

export { Tab, type TabProps };
