import type { FC } from 'react';

interface ErrorTextProps {
  errorText?: string;
}

const ErrorText: FC<ErrorTextProps> = ({ errorText }) => {
  if (!errorText) return null;

  return <span className="label-2 text-error">{errorText}</span>;
};

export { ErrorText, type ErrorTextProps };
