import type { FC } from 'react';

import {
  ArrowDropDownIcon,
  ArrowDropUpIcon,
  NeutralIcon,
} from '@pxui/components/ui/icons';

interface DiffPrefixProps {
  value: number;
  withIcon?: boolean;
}

const DiffPrefix: FC<DiffPrefixProps> = ({ value, withIcon }) => {
  if (value > 0) {
    return withIcon ? <ArrowDropUpIcon size="large" /> : '+';
  }
  if (value < 0) {
    return withIcon ? <ArrowDropDownIcon size="large" /> : '-';
  }
  return withIcon ? <NeutralIcon size="large" /> : '';
};

export { DiffPrefix, type DiffPrefixProps };
