import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
} from 'react';

import { cn } from '@pxui/lib/utils';
import * as SwitchPrimitives from '@radix-ui/react-switch';

interface ToggleSwitchProps
  extends ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  label?: string;
}

const layoutClasses =
  'peer group inline-flex shrink-0 items-center rounded-full border-2 border-transparent';

const focusVisibleClasses =
  'focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-1';

const dataStateUncheckedClasses =
  'data-[state=unchecked]:disabled:button-disabled data-[state=unchecked]:bg-icon-on-color-disabled data-[state=unchecked]:focus-visible:outline-state-focus';

const dataStateCheckedClasses =
  'data-[state=checked]:disabled:button-disabled data-[state=checked]:bg-checkbox-primary data-[state=checked]:focus-visible:outline-checkbox-primary';

const ToggleSwitch = forwardRef<
  ElementRef<typeof SwitchPrimitives.Root>,
  ToggleSwitchProps
>(({ className, label, ...props }, ref) => (
  <div className={cn('inline-flex gap-1.5', className)}>
    <SwitchPrimitives.Root
      className={cn(
        'h-4 w-6 cursor-pointer transition-colors disabled:cursor-not-allowed',
        layoutClasses,
        focusVisibleClasses,
        dataStateUncheckedClasses,
        dataStateCheckedClasses,
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          'pointer-events-none group-disabled:bg-icon-disabled block h-3 w-3 rounded-full bg-icon-primary ring-0 transition-transform data-[state=checked]:translate-x-2 data-[state=unchecked]:translate-x-0',
        )}
      />
    </SwitchPrimitives.Root>

    {!!label && (
      <span className="label-1 text-primary peer-disabled:text-disabled">
        {label}
      </span>
    )}
  </div>
));
ToggleSwitch.displayName = 'ToggleSwitch';

export { ToggleSwitch, type ToggleSwitchProps };
