import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Send: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.4165 15.375V4.62506L16.926 10L3.4165 15.375ZM4.49982 13.7709L13.9581 10L4.49982 6.22919V8.66029L8.519 10L4.49982 11.3397V13.7709Z"
      fill="currentColor"
    />
  </svg>
);

export default Send;
