import { forwardRef } from 'react';

import { FileInput, FileInputProps } from '@pxui/components/ui/fileInput';
import { cn } from '@pxui/lib/utils';

import { DetailsError } from './detailsError';

interface DetailsFileInputProps extends FileInputProps {
  error?: string;
  label: string;
}

const DetailsFileInput = forwardRef<HTMLDivElement, DetailsFileInputProps>(
  ({ className, label, id, value, onChange, error, ...props }, ref) => (
    <>
      <div
        ref={ref}
        className={cn(
          'flex w-full py-[5px] px-4 justify-between items-start flex-wrap',
          className,
        )}
      >
        <span className="label-1 text-secondary">{label}</span>
        <FileInput onChange={onChange} value={value} id={id} {...props} />
      </div>

      <DetailsError text={error} />
    </>
  ),
);

DetailsFileInput.displayName = 'DetailsFileInput';

export { DetailsFileInput, type DetailsFileInputProps };
