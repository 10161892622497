import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
} from 'react';

import { cn } from '@pxui/lib/utils';
import { Root } from '@radix-ui/react-toast';
import { cva, type VariantProps } from 'class-variance-authority';

const toastVariants = cva(
  'group pointer-events-auto relative flex w-full items-center surface-01 justify-between space-x-4 overflow-hidden rounded-xl border elevation-04 p-4 transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full focus-visible:outline-none',
  {
    defaultVariants: {
      variant: 'default',
    },
    variants: {
      variant: {
        default: 'default text-primary',
        error: 'destructive group border-auxiliary-error text-error',
        success: 'success border-auxiliary-success text-auxiliary-success',
      },
    },
  },
);

export type ToastVariants = VariantProps<typeof toastVariants>;

export const Toast = forwardRef<
  ElementRef<typeof Root>,
  ComponentPropsWithoutRef<typeof Root> & ToastVariants
>(({ className, variant, ...props }, ref) => (
  <Root
    ref={ref}
    className={cn(toastVariants({ variant }), className)}
    onSwipeMove={(event) => {
      event.preventDefault();
    }}
    data-testid="toast"
    {...props}
  />
));

Toast.displayName = Root.displayName;

export default Toast;
