import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

const layoutClasses =
  'inline-flex flex-col items-start rounded-lg w-full h-[168px]';
const spacingClasses = 'p-2 gap-2.5';

export const DetailsPreview = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(layoutClasses, spacingClasses, className)}
    {...props}
  >
    <div className="flex justify-center items-center w-full h-full rounded">
      {children}
    </div>
  </div>
));

DetailsPreview.displayName = 'DetailsPreview';

export default DetailsPreview;
