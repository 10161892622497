import { type HTMLAttributes, forwardRef } from 'react';

import cn from '@pxui/lib/utils';

export const TileHeader = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('flex flex-col', className)} {...props} />
));

TileHeader.displayName = 'TileHeader';

export default TileHeader;
