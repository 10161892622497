import {
  type MouseEventHandler,
  useEffect,
  useState,
  type FC,
  type ReactNode,
} from 'react';

import {
  TreeViewBranchCaret,
  ViewItemContent,
  type ViewItemContentProps,
} from '@pxui/components/ui/view';
import { Root, Content } from '@radix-ui/react-collapsible';

interface TreeViewBranchProps extends Omit<ViewItemContentProps, 'caret'> {
  children?: ReactNode;
  onClick?: () => Promise<void>;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
}

const TreeViewBranch: FC<TreeViewBranchProps> = ({
  nestLevel = 0,
  children,
  icon,
  selected,
  label,
  onClick,
  onOpenChange,
  open = false,
  textRight,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleClick: MouseEventHandler<HTMLDivElement> = async (event) => {
    event.stopPropagation();
    if (onClick) {
      await onClick();
    }
  };

  const handleOpenChange = (openValue: boolean) => {
    if (onOpenChange) {
      onOpenChange(openValue);
    } else {
      setIsOpen(openValue);
    }
  };

  useEffect(() => {
    if (open !== isOpen) {
      setIsOpen(open);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Root className="w-full flex flex-col" open={isOpen} onClick={handleClick}>
      <div className="w-full px-1.5 h-9 flex">
        <ViewItemContent
          selected={!!selected}
          textRight={isOpen ? '' : textRight}
          label={label}
          icon={icon}
          nestLevel={nestLevel}
          caret={
            <TreeViewBranchCaret
              isOpen={isOpen}
              onOpenChange={handleOpenChange}
            />
          }
        />
      </div>

      {!!children && <Content>{children}</Content>}
    </Root>
  );
};

export { TreeViewBranch, type TreeViewBranchProps };
