import type { FC, HTMLAttributes } from 'react';

import cn from '@pxui/lib/utils';

const layoutClasses = 'flex flex-col w-full';
const spacingClasses = 'gap-4 p-4';

const Section: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <section className={cn(layoutClasses, spacingClasses, className)} {...props}>
    {children}
  </section>
);

export default Section;
