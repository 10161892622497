import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

export const DetailsString = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <p
    ref={ref}
    className={cn(
      'flex flex-col w-full py-[5px] px-4 justify-between items-start text-secondary label-1',
      className,
    )}
    {...props}
  >
    {children}
  </p>
));

DetailsString.displayName = 'DetailsString';

export default DetailsString;
