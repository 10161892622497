import type { FC, HTMLAttributes } from 'react';

import { cn } from '@pxui/lib/utils';

export const DropdownMenuShortcut: FC<HTMLAttributes<HTMLSpanElement>> = ({
  className,
  ...props
}) => (
  <span
    className={cn(
      'ml-auto h-8 w-8 label-1 text-placeholder flex justify-center items-center',
      className,
    )}
    {...props}
  />
);

DropdownMenuShortcut.displayName = 'DropdownMenuShortcut';

export default DropdownMenuShortcut;
