import React from 'react';

import { cn } from '@pxui/lib/utils';
import { Command as CommandPrimitive } from 'cmdk';

export const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
    testId: string;
  }
>(({ className, testId, ...props }, ref) => (
  <div
    className="flex items-center border-01-subtle border-b"
    // eslint-disable-next-line react/no-unknown-property
    cmdk-input-wrapper=""
  >
    <CommandPrimitive.Input
      ref={ref}
      className={cn(
        'flex w-full rounded-md bg-transparent my-6 mx-8 outline-none placeholder:text-secondary placeholder:title-2 disabled:cursor-not-allowed disabled:opacity-50 text-primary title-2',
        className,
      )}
      data-testid={testId}
      {...props}
    />
  </div>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;

export default CommandInput;
