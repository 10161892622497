import { ReactNode, forwardRef } from 'react';

import cn from '@pxui/lib/utils';
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';

export const ContextMenuItem = forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Item> & {
    inset?: boolean;
    leadingElement?: ReactNode;
    trailingElement?: ReactNode;
  }
>(
  (
    { className, inset, leadingElement, trailingElement, children, ...props },
    ref,
  ) => (
    <ContextMenuPrimitive.Item
      ref={ref}
      className={cn(
        'relative flex min-h-8 p-1.5 cursor-default select-none justify-between items-center label-1 text-white outline-none hover:bg-white/[.08] data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        inset && !leadingElement && 'pl-8',
        className,
      )}
      {...props}
    >
      <div className="flex items-center gap-1.5">
        {leadingElement}
        {children}
      </div>
      {trailingElement}
    </ContextMenuPrimitive.Item>
  ),
);
ContextMenuItem.displayName = ContextMenuPrimitive.Item.displayName;

export default ContextMenuItem;
