import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const DeployedCode: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M9.45768 16.1828V10.3174L4.49935 7.4472V13.1685C4.49935 13.2111 4.51004 13.2512 4.53143 13.2887C4.55282 13.326 4.58483 13.358 4.62747 13.3847L9.45768 16.1828ZM10.541 16.1828L15.3712 13.4055C15.4139 13.3789 15.4459 13.3469 15.4673 13.3095C15.4887 13.272 15.4993 13.2319 15.4993 13.1893V7.46804L10.541 10.3174V16.1828ZM9.99935 9.36554L15.1035 6.43283L10.1066 3.5722C10.064 3.54554 10.0213 3.5322 9.97852 3.5322C9.93574 3.5322 9.89303 3.54554 9.85039 3.5722L4.89518 6.43283L9.99935 9.36554ZM4.08602 14.3366C3.87518 14.2153 3.71081 14.0531 3.59289 13.8499C3.47497 13.6466 3.41602 13.4237 3.41602 13.1812V6.81908C3.41602 6.57658 3.47497 6.35366 3.59289 6.15033C3.71081 5.94714 3.87518 5.78491 4.08602 5.66366L9.30872 2.64116C9.51803 2.51825 9.74824 2.45679 9.99935 2.45679C10.2505 2.45679 10.4807 2.51825 10.69 2.64116L15.9127 5.66366C16.1235 5.78491 16.2879 5.94714 16.4058 6.15033C16.5237 6.35366 16.5827 6.57658 16.5827 6.81908V13.1812C16.5827 13.4237 16.5237 13.6466 16.4058 13.8499C16.2879 14.0531 16.1235 14.2153 15.9127 14.3366L10.6691 17.3799C10.458 17.5028 10.2343 17.5643 9.99789 17.5643C9.76164 17.5643 9.53886 17.5028 9.32956 17.3799L4.08602 14.3366Z"
      fill="currentColor"
    />
  </svg>
);

export default DeployedCode;
