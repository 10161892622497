import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

export const DetailsListItem = forwardRef<
  HTMLLIElement,
  HTMLAttributes<HTMLLIElement>
>(({ className, children, ...props }, ref) => (
  <li
    ref={ref}
    className={cn(
      'w-full py-[5px] ml-6 pr-6 text-secondary label-1',
      className,
    )}
    {...props}
  >
    {children}
  </li>
));

DetailsListItem.displayName = 'DetailsListItem';

export default DetailsListItem;
