import { forwardRef, type HTMLAttributes } from 'react';

import { cn } from '@pxui/lib/utils';

const layoutClasses =
  'group rounded-xl inline-flex flex-col justify-start items-start min-w-[280px] w-[280px] overflow-hidden';
const spacingClasses = 'gap-6';
const colorClasses = 'text-primary';

export const DetailsCard = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    elevation?:
      | 'elevation-00'
      | 'elevation-01'
      | 'elevation-02'
      | 'elevation-04';
    surface?: 'surface-00' | 'surface-01';
  }
>(
  (
    { className, surface = 'surface-01', elevation = 'elevation-04', ...props },
    ref,
  ) => (
    <div
      ref={ref}
      className={cn(
        layoutClasses,
        spacingClasses,
        colorClasses,
        surface,
        elevation,
        className,
      )}
      {...props}
    />
  ),
);

DetailsCard.displayName = 'DetailsCard';

export default DetailsCard;
