import apiClient from '@services/interceptors/authInterceptor';

const termsConditionsMarkAccepted = async (version: number) => {
  const response = await apiClient.post(
    `${process.env.PLATFORM_APP_MANAGEMENT_API}/v0/users/termsConditions/${version}:mark_accepted`,
  );

  return response;
};

export default termsConditionsMarkAccepted;
