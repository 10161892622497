import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const PromptSuggestion: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.39898 15.5833L8.173 14.8253L5.49831 12.1827H13.3637C14.2558 12.1827 15.0154 11.8731 15.6425 11.254C16.2697 10.6349 16.5833 9.87927 16.5833 8.98719C16.5833 8.0951 16.2697 7.3395 15.6425 6.72038C15.0154 6.10125 14.2558 5.79169 13.3637 5.79169H12.8797V6.875H13.3637C13.9558 6.875 14.4598 7.07856 14.8759 7.48569C15.2919 7.89281 15.4999 8.39175 15.4999 8.9825C15.4999 9.57324 15.2919 10.0737 14.8759 10.484C14.4598 10.8942 13.9558 11.0994 13.3637 11.0994H5.49831L8.173 8.45675L7.39898 7.69073L3.41663 11.641L7.39898 15.5833Z"
      fill="#F4F4F4"
    />
  </svg>
);

export default PromptSuggestion;
