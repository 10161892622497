import type { FC, HTMLAttributes, MouseEvent as ReactMouseEvent } from 'react';

import { ArrowDropDownIcon, ArrowRightIcon } from '@pxui/components/ui/icons';
import cn from '@pxui/lib/utils';

interface TreeViewBranchCaretProps extends HTMLAttributes<HTMLButtonElement> {
  isOpen?: boolean;
  onOpenChange: (open: boolean) => void;
}

const TreeViewBranchCaret: FC<TreeViewBranchCaretProps> = ({
  isOpen,
  onOpenChange,
  className,
}) => {
  const handleClick = (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    onOpenChange(!isOpen);
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={cn('flex items-center px-2 h-full', className)}
    >
      {isOpen ? (
        <ArrowDropDownIcon className="text-on-surface-subtle" />
      ) : (
        <ArrowRightIcon className="text-on-surface-subtle" />
      )}
    </button>
  );
};

export { TreeViewBranchCaret, type TreeViewBranchCaretProps };
