import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Check: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      d="M8.22927 13.774L5.01294 10.5369L5.75813 9.7917L8.22927 12.242L14.2421 6.25003L14.9873 7.01603L8.22927 13.774Z"
      fill="currentColor"
    />
  </svg>
);

export default Check;
