import { Dropdown, DropdownMenuItem } from '@pxui/components/ui';
import { DropdownMenuGroup } from '@radix-ui/react-dropdown-menu';

import { MeshRepresentationLabel } from '../GeometryViewer/settings';
import { MeshViewerSlider } from '../Slider/MeshViewerSlider';

interface SidebarMeshSettingsProps {
  activeColorMapPreset: string;
  activeField: string;
  colorMapPresets: string[];
  fields: string[];
  onColorPresetChange: (preset: string) => void;
  onFieldChange: (field: string) => void;
  onLineWidthChange: (lineWidth: number[]) => void;
  onPointSizeChange: (pointSizeChange: number[]) => void;
  onRepresentationChange: (repr: MeshRepresentationLabel) => void;
  onToggleVertexVisibility: (visible: boolean) => void;
  representation: string;
  vertexVisibility: boolean;
}

function SidebarMeshSettings({
  colorMapPresets,
  activeColorMapPreset,
  fields,
  activeField,
  representation,
  onRepresentationChange,
  onFieldChange,
  onColorPresetChange,
  vertexVisibility,
  onLineWidthChange,
  onToggleVertexVisibility,
  onPointSizeChange,
}: SidebarMeshSettingsProps) {
  const vertexVisibilityLabel = vertexVisibility ? 'True' : 'False';
  const vertexVisibilityLabels = ['True', 'False'];

  const handleToggleVertexVisibility = (label: string) => {
    const visible = label === 'True';
    onToggleVertexVisibility(visible);
  };

  const pointSizeIsVisible = representation === 'Points' || vertexVisibility;
  const lineWidthIsVisible =
    representation !== 'Points' && representation !== 'Surface';

  return (
    <>
      <div className="label-1-strong text-secondary mb-5">Mesh settings</div>
      <div>
        <div className="flex flex-col mb-5">
          <div className="mb-3">
            <span className="label-1-strong">Fields</span>
          </div>
          <div className="">
            <Dropdown variant="secondary" buttonText={`${activeField}`}>
              <DropdownMenuGroup>
                {fields.map((field: any) => (
                  <DropdownMenuItem
                    key={field}
                    onClick={() => onFieldChange(field)}
                  >
                    {field}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuGroup>
            </Dropdown>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col mb-5">
          <div className="mb-3">
            <span className="label-1-strong">Visibility</span>
          </div>
          <div className="">
            <Dropdown variant="secondary" buttonText={`${representation}`}>
              <DropdownMenuGroup>
                <DropdownMenuItem
                  onClick={() => onRepresentationChange('Points')}
                >
                  Points
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => onRepresentationChange('Wireframe')}
                >
                  Wireframe
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => onRepresentationChange('Surface')}
                >
                  Surface
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => onRepresentationChange('SurfaceWithEdges')}
                >
                  SurfaceWithEdges
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </Dropdown>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col mb-5">
          <div className="mb-3">
            <span className="label-1-strong">Preset</span>
          </div>
          <div className="">
            <Dropdown
              variant="secondary"
              buttonText={`${activeColorMapPreset}`}
            >
              <DropdownMenuGroup>
                {colorMapPresets.map((preset: any) => (
                  <DropdownMenuItem
                    key={preset}
                    onClick={() => onColorPresetChange(preset)}
                  >
                    {preset}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuGroup>
            </Dropdown>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col mb-5">
          <div className="mb-3">
            <span className="label-1-strong">Vertex visibility</span>
          </div>
          <div className="">
            <Dropdown
              variant="secondary"
              buttonText={`${vertexVisibilityLabel}`}
            >
              <DropdownMenuGroup>
                {vertexVisibilityLabels.map((label: string) => (
                  <DropdownMenuItem
                    key={label}
                    onClick={() => handleToggleVertexVisibility(label)}
                  >
                    {label}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuGroup>
            </Dropdown>
          </div>
        </div>
        {pointSizeIsVisible && (
          <div className="flex flex-col mb-5">
            <div className="mb-3">
              <span className="label-1-strong">Point Size</span>
            </div>
            <div className="w-full">
              <MeshViewerSlider
                min={0.1}
                max={10}
                step={0.01}
                size="small"
                onValueChange={onPointSizeChange}
              />
            </div>
          </div>
        )}
        {lineWidthIsVisible && (
          <div className="flex flex-col">
            <div className="mb-3">
              <span className="label-1-strong">Line Width</span>
            </div>
            <div className="w-full">
              <MeshViewerSlider
                min={0.1}
                max={5}
                step={0.01}
                size="small"
                onValueChange={onLineWidthChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default SidebarMeshSettings;
