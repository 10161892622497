import { User } from '@auth0/auth0-react';
import {
  CodeBlockIcon,
  DetailsDivider,
  Dropdown,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  KeyIcon,
  LogoutIcon,
  TerminalIcon,
} from '@pxui/components/ui';
import cn from '@pxui/lib/utils';
import { useRecoilState } from 'recoil';

import useLatestFluxCLI from '@hooks/useLatestFluxCLI';
import { termsAndConditionsState } from '@states/latestTermsAndConditions';

import {
  PAT_TOKEN_API_DOCS_URL,
  PLATFORM_API_DOCS_URL,
} from '@constants/common';
import { textEllipsis } from '@constants/css';

import UserAvatar from './UserAvatar';

type UserProfileType = {
  logoutHandler: (logoutParams: any) => void;
  user?: User;
};

const UserProfile = ({ user, logoutHandler }: UserProfileType) => {
  const userName = user?.name || '';
  const gitVersion = process.env.GIT_VERSION
    ? JSON.parse(process.env.GIT_VERSION).MajorMinorPatch
    : '0.0.0';
  const shaCode = process.env.GIT_VERSION
    ? JSON.parse(process.env.GIT_VERSION).Sha
    : 'sha code';
  const { handleFluxCLIDownloadClick } = useLatestFluxCLI();
  const [termsAndConditions] = useRecoilState(termsAndConditionsState);

  return (
    <Dropdown
      buttonText={<span data-hj-suppress>{userName}</span>}
      variant="ghost"
      contentClassName="w-60 mr-2"
    >
      <DropdownMenuGroup key="menu-group-user">
        <DropdownMenuItem
          inset
          key="menu-item-user"
          className="w-60 h-auto py-1 px-2 pointer-events-none"
        >
          <UserAvatar userName={userName} />
          <div className="py-0.5 px-2" data-hj-suppress>
            <div className="label-1 text-on-surface">{userName}</div>
            <div className="label-2 text-on-surface-subtle ">
              {user?.email ?? ''}
            </div>
          </div>
        </DropdownMenuItem>
      </DropdownMenuGroup>
      <DetailsDivider className="px-0 py-2" />
      <DropdownMenuLabel className="label-2 text-on-surface-subtle px-3 py-2">
        Developers
      </DropdownMenuLabel>

      <DropdownMenuGroup key="menu-group-developer">
        <a href={PAT_TOKEN_API_DOCS_URL} target="_blank" rel="noreferrer">
          <DropdownMenuItem
            inset
            key="menu-item-pat-token"
            className="gap-2 w-60 label-1 text-on-surface"
            icon={KeyIcon}
          >
            PAT tokens
          </DropdownMenuItem>
        </a>

        <a href={PLATFORM_API_DOCS_URL} target="_blank" rel="noreferrer">
          <DropdownMenuItem
            inset
            key="menu-item-api-docs"
            className="gap-2 w-60 label-1 text-on-surface"
            icon={CodeBlockIcon}
          >
            API Documentation
          </DropdownMenuItem>
        </a>

        <button onClick={handleFluxCLIDownloadClick} type="button">
          <DropdownMenuItem
            inset
            key="menu-item-download-flux-cli"
            className="gap-2 w-60 label-1 text-on-surface"
            icon={TerminalIcon}
          >
            Flux CLI
          </DropdownMenuItem>
        </button>
      </DropdownMenuGroup>
      <DetailsDivider className="px-0 py-2" />
      <DropdownMenuGroup key="menu-group-logout">
        <DropdownMenuItem
          inset
          key="menu-item-logout"
          onClick={() =>
            logoutHandler({
              logoutParams: { returnTo: window.location.origin },
            })
          }
          className="gap-2 w-60 label-1 text-on-surface"
          icon={LogoutIcon}
        >
          Log out
        </DropdownMenuItem>
        <DropdownMenuLabel className="label-1 text-on-surface px-4 py-2">
          <div className="flex flex-row">
            <button
              type="button"
              title={shaCode}
              className={cn(
                'label-1 text-link-primary hover:text-link-primary-hover',
                textEllipsis,
              )}
              onClick={() => {
                navigator.clipboard.writeText(shaCode);
              }}
            >{`v${gitVersion} `}</button>
            <div className="opacity-50">&nbsp; ·</div>
            <a
              href={termsAndConditions?.url}
              download={
                termsAndConditions?.fileName ??
                'PhysicsX End User Licence Agreement.pdf'
              }
              rel="noreferrer"
              className="text-link-primary pl-1 label-1 hover:text-link-primary-hover"
            >
              Terms
            </a>
            <div className="opacity-50"> &nbsp; · Privacy</div>
          </div>
        </DropdownMenuLabel>
      </DropdownMenuGroup>
    </Dropdown>
  );
};

export default UserProfile;
