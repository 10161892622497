import type { FC, HTMLAttributes } from 'react';

import { cn } from '@pxui/lib/utils';

export const ListViewDivider: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => (
  <div className={cn('px-1.5 py-2 border-subtle w-full', className)} {...props}>
    <div className="w-full border-t border-inherit" />
  </div>
);

export default ListViewDivider;
