import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

export const DetailsDivider = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('w-full py-1 px-4 border-01-subtle', className)}
    {...props}
  >
    <div className="w-full border-t border-inherit" />
  </div>
));

DetailsDivider.displayName = 'DetailsDivider';

export default DetailsDivider;
