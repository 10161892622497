import { FunctionComponent, type InputHTMLAttributes, forwardRef } from 'react';

import {
  ErrorText,
  HelperText,
  Label,
  RangeLabel,
} from '@pxui/components/ui/textInput';
import { cn } from '@pxui/lib/utils';

import { IconProps } from '../icons';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  errorText?: string;
  helperText?: string;
  icon?: FunctionComponent<IconProps>;
  id: string;
  labelText?: string;
  leftLabel?: number;
  rightLabel?: number;
  wrapperClassName?: string;
}

const layoutClasses = 'peer flex rounded outline-0';
const spacingClasses = 'pl-3 pr-1.5 py-2';
const placeholderClasses = 'placeholder:label-1 placeholder:text-placeholder';
const inputStateClasses =
  'focus-within:state-focus disabled:text-disabled disabled:placeholder:text-disabled invalid:border-auxiliary-error';

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      type,
      id,
      labelText,
      helperText,
      errorText,
      disabled,
      wrapperClassName,
      leftLabel,
      rightLabel,
      icon: Icon,
      ...props
    },
    ref,
  ) => {
    const wrapperClasses = cn('flex flex-col gap-2', wrapperClassName);

    const labelClasses = cn('label-2 text-primary', {
      'text-disabled': disabled,
    });

    const inputClasses = cn(
      'label-1 text-primary w-full elevation-00 bg-field-01 group-[.surface-01]:bg-surface-container-low',
      layoutClasses,
      spacingClasses,
      placeholderClasses,
      inputStateClasses,
      className,
      errorText ? 'border-danger' : '',
      Icon ? 'pl-[30px]' : '',
    );

    // TODO add generic text input validation for all "text" and "search" type input fields
    return (
      <div className={wrapperClasses}>
        <Label text={labelText} className={labelClasses} htmlFor={id} />
        <RangeLabel left={leftLabel} right={rightLabel}>
          <div className="relative">
            {Icon && (
              <Icon className="fill-icon-primary absolute top-1/2 left-[5px] -translate-y-1/2 transition-colors text-primary" />
            )}
            <input
              id={id}
              type={type}
              disabled={disabled}
              className={inputClasses}
              ref={ref}
              {...props}
            />
          </div>
          {!errorText && <HelperText helperText={helperText} />}
          <ErrorText errorText={errorText} />
        </RangeLabel>
      </div>
    );
  },
);
TextInput.displayName = 'TextInput';

export { TextInput };
