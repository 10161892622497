import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Upload: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.45842 12.8237V5.49035L7.3831 7.56567L6.60908 6.80769L10.0001 3.41669L13.391 6.80769L12.617 7.56567L10.5417 5.49035V12.8237H9.45842ZM5.75058 15.5833C5.37525 15.5833 5.05911 15.4538 4.80216 15.1948C4.54522 14.9357 4.41675 14.6187 4.41675 14.2436V12.984H5.50006V14.2436C5.50006 14.3077 5.52677 14.3664 5.58019 14.4199C5.63362 14.4733 5.69239 14.5 5.7565 14.5H14.2436C14.3077 14.5 14.3665 14.4733 14.4199 14.4199C14.4734 14.3664 14.5001 14.3077 14.5001 14.2436V12.984H15.5834V14.2436C15.5834 14.6187 15.4538 14.9357 15.1946 15.1948C14.9354 15.4538 14.6181 15.5833 14.2428 15.5833H5.75058Z"
      fill="currentColor"
    />
  </svg>
);

export default Upload;
