import type { FC, HTMLAttributes } from 'react';

import { cn } from '@pxui/lib/utils';

export function createViewComponent(displayName: string) {
  const Component: FC<HTMLAttributes<HTMLDivElement>> = ({
    className,
    children,
    ...props
  }) => (
    <div
      className={cn('w-full flex flex-col items-start', className)}
      {...props}
    >
      {children}
    </div>
  );

  Component.displayName = displayName;

  return Component;
}

export default createViewComponent;
