import type { FC, MouseEventHandler } from 'react';

import {
  ViewItemContent,
  type ViewItemContentProps,
} from '@pxui/components/ui/view';

interface TreeViewLeafProps extends Omit<ViewItemContentProps, 'caret'> {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const TreeViewLeaf: FC<TreeViewLeafProps> = ({
  nestLevel = 0,
  icon,
  label,
  onClick,
  selected,
  textRight,
}) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    onClick?.(event);
  };

  return (
    <button
      className="px-1.5 w-full h-9 flex"
      onClick={handleClick}
      type="button"
    >
      <ViewItemContent
        icon={icon}
        selected={!!selected}
        textRight={textRight}
        nestLevel={nestLevel}
        caret={
          <div className="flex-shrink-0 flex px-2">
            <div className="w-5 h-5" />
          </div>
        }
        label={label}
      />
    </button>
  );
};

export { TreeViewLeaf, type TreeViewLeafProps };
