import cn from '@pxui/lib/utils';

interface LoadingIndicatorProps {
  className?: string;
}

export function LoadingIndicator({ className }: LoadingIndicatorProps) {
  return (
    <div className={cn('text-primary z-10 absolute top-[50%]', className)}>
      Loading mesh...
    </div>
  );
}

export default LoadingIndicator;
