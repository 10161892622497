import { MutableRefObject, useCallback } from 'react';

import { ClipPropertiesPanelProps } from '../components/ClipPlaneProperties/ClipPlaneProperties';
import { GeometryViewer } from '../components/GeometryViewer/GeometryViewerFactory';

export function useClipFilterProps(
  viewer?: MutableRefObject<GeometryViewer | undefined>,
): ClipPropertiesPanelProps & {
  onActivateFilter: () => void;
  onDeactivateFilter: () => void;
} {
  const handleClipMesh = useCallback(async () => {
    viewer?.current?.clipAndVisualize();
  }, [viewer]);

  const handleClipFilterActivation = useCallback(() => {
    viewer?.current?.activateClipFilter();
  }, [viewer]);

  const handleClipFilterDeactivation = useCallback(() => {
    viewer?.current?.deactivateClipFilter();
  }, [viewer]);

  const handleToggleClipPlaneVisibility = useCallback(
    (visible: boolean) => {
      if (visible) {
        viewer?.current?.showClipPlaneRep();
      } else {
        viewer?.current?.hideClipPlaneRep();
      }
    },
    [viewer],
  );

  const handleToggleClipOutlineVisibility = useCallback(
    (visible: boolean) => {
      if (visible) {
        viewer?.current?.showClipOutline();
      } else {
        viewer?.current?.hideClipOutline();
      }
    },
    [viewer],
  );

  const handleSetClipPlaneNormal = useCallback(
    (x: number, y: number, z: number) => {
      viewer?.current?.setClipPlaneRepNormal(x, y, z);
    },
    [viewer],
  );
  return {
    onActivateFilter: handleClipFilterActivation,
    onClipSettingsChange: console.log,
    onDeactivateFilter: handleClipFilterDeactivation,
    onSetClipPlaneNormal: handleSetClipPlaneNormal,
    onToggleClipOutlineVisibility: handleToggleClipOutlineVisibility,
    onToggleClipPlaneVisibility: handleToggleClipPlaneVisibility,
    triggerClip: handleClipMesh,
  };
}

export default useClipFilterProps;
