import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const ArrowLeftAlt: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      d="M7.9602 13.7115L4.24854 10L7.9602 6.28857L8.73416 7.04649L6.32229 9.45837H15.7519V10.5417H6.32229L8.73416 12.9536L7.9602 13.7115Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowLeftAlt;
