import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';
import { Close } from '@radix-ui/react-toast';
import { X } from 'lucide-react';

export const ToastClose = forwardRef<
  ElementRef<typeof Close>,
  ComponentPropsWithoutRef<typeof Close>
>(({ className, ...props }, ref) => (
  <Close
    ref={ref}
    className={cn(
      'absolute right-2 top-2 rounded-md p-1 text-slate-950/50 opacity-0 transition-opacity hover:text-slate-950 focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600 group-[.success]:text-green-300 group-[.success]:hover:text-green-50 group-[.success]:focus:ring-green-400 group-[.success]:focus:ring-offset-green-600 group-[.default]:text-gray-300 group-[.default]:hover:text-gray-50 group-[.default]:focus:ring-gray-700 group-[.default]:focus:ring-offset-gray-700',
      className,
    )}
    toast-close=""
    {...props}
  >
    <X className="h-4 w-4" />
  </Close>
));

ToastClose.displayName = Close.displayName;

export default ToastClose;
