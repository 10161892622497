import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const ArrowRight: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      d="M8.20825 13.5032V6.49683L11.7114 9.99999L8.20825 13.5032Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowRight;
