import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

export const TableFooter = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      'border-t bg-slate-100/50 font-medium [&>tr]:last:border-b-0 dark:bg-slate-800/50',
      className,
    )}
    {...props}
  />
));

TableFooter.displayName = 'TableFooter';

export default TableFooter;
