import { colors } from '@design-tokens/primitive-tokens';
import { DotIcon } from '@pxui/components/ui/icons';
import cn from '@pxui/lib/utils';

interface BadgeProps {
  border?: boolean;
  className?: string;
  label: string;
  pills?: boolean;
  size?: 'medium' | 'small';
  variant?: 'success' | 'primary' | 'error' | 'warning' | 'default';
}

const commonClasses =
  'flex box-border items-center w-max bg-surface-container-low rounded';
const mediumClasses = 'h-6 px-2 py-1 gap-[6px] label-1';
const smallClasses = 'h-5 px-[6px] py-[2px] gap-1 label-2';

const getColorClass = (variant: BadgeProps['variant']) => {
  switch (variant) {
    case 'success':
      return 'text-key-success border border-key-success';
    case 'primary':
      return 'text-key-primary border border-key-primary';
    case 'error':
      return 'text-key-error border border-key-error';
    case 'warning':
      return 'text-key-warning border border-key-warning';
    default:
      return 'text-key-default border border-key-default';
  }
};

const getIconColor = (variant: BadgeProps['variant']) => {
  switch (variant) {
    case 'success':
      return colors.key.success;
    case 'primary':
      return colors.key.primary;
    case 'error':
      return colors.key.error;
    case 'warning':
      return colors.key.warning;
    default:
      return colors.key.default;
  }
};

const Badge = ({
  border = false,
  className,
  label,
  pills = false,
  size = 'medium',
  variant = 'default',
}: BadgeProps) => {
  const isMedium = size === 'medium';
  const colorClasses = getColorClass(variant);
  const containerClasses = isMedium ? mediumClasses : smallClasses;
  const iconColor = getIconColor(variant);
  const iconViebox = isMedium ? `0 0 10 10` : `0 0 8 8`;
  const iconCxCy = isMedium ? `5` : `4`;
  const iconR = isMedium ? `4` : `3`;
  const iconSize = isMedium ? `xsmall` : `xxsmall`;

  return (
    <div
      className={cn(
        commonClasses,
        containerClasses,
        colorClasses,
        {
          'border-0': !border,
          'rounded-full': pills,
        },
        className,
      )}
    >
      <DotIcon
        color={iconColor}
        cx={iconCxCy}
        cy={iconCxCy}
        r={iconR}
        size={iconSize}
        viewBox={iconViebox}
      />
      <span>{label}</span>
    </div>
  );
};

Badge.displayName = 'Badge';

export { Badge };
export type { BadgeProps };
