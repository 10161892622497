// Hotjar is an analytics tool that provides insights into user interactions

/* eslint-disable no-param-reassign, no-underscore-dangle, no-param-reassign */

const initHotjar = (): void => {
  (function (
    hjWindow: any,
    doc: Document,
    settingsPrefix: string,
    versionSuffix: string,
  ) {
    const hotjar =
      hjWindow.hj ||
      function (...args: any) {
        (hjWindow.hj.q = hjWindow.hj.q || []).push(args);
      };

    hjWindow.hj = hotjar;
    hjWindow._hjSettings = { hjid: 5180175, hjsv: 6 };

    const headElement = doc.getElementsByTagName('head')[0];
    const scriptElement = doc.createElement('script');
    scriptElement.async = true;
    scriptElement.src =
      settingsPrefix +
      hjWindow._hjSettings.hjid +
      versionSuffix +
      hjWindow._hjSettings.hjsv;
    headElement.appendChild(scriptElement);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
};

export default initHotjar;
