import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
  useToast,
} from '@pxui/components/ui/toast';
import { ToastType, ToasterToast } from '@pxui/components/ui/toast/types';

import { ToastCancel } from './toastCancel';
import { ToastProgress } from './toastProgress';

const renderToast = (toast: ToasterToast) => {
  const {
    id,
    title,
    description,
    action,
    withXButton = true,
    details,
    toastType = ToastType.Default,
    onCancel,
    ...props
  } = toast;

  switch (toastType) {
    case ToastType.Progress:
      return (
        <Toast key={id} {...props}>
          <div className="grid gap-1 w-full mt-1 mr-3">
            {title && <ToastTitle>{title}</ToastTitle>}

            {details && (
              <ToastDescription>
                <ToastProgress {...details} />
              </ToastDescription>
            )}
          </div>
          {action}
          {withXButton && <ToastClose />}
          {onCancel && <ToastCancel onClick={() => onCancel(id)} />}
        </Toast>
      );
    case ToastType.Default:
    default:
      return (
        <Toast key={id} {...props}>
          <div className="grid gap-1 w-full">
            {title && <ToastTitle>{title}</ToastTitle>}
            {description && <ToastDescription>{description}</ToastDescription>}
          </div>
          {action}
          {withXButton && <ToastClose />}
        </Toast>
      );
  }
};

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map((toast) => renderToast(toast))}
      <ToastViewport />
    </ToastProvider>
  );
}

export default Toaster;
