import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const FolderOpen: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.70817 15.5833C3.31714 15.5833 3.00411 15.4577 2.76907 15.2065C2.53402 14.9554 2.4165 14.6501 2.4165 14.2908V5.75052C2.4165 5.39122 2.54204 5.07908 2.79311 4.81413C3.04418 4.54917 3.36523 4.41669 3.75625 4.41669H7.83152L9.83152 6.41669H16.2642C16.6093 6.41669 16.8892 6.51978 17.1039 6.72598C17.3187 6.93217 17.4613 7.19018 17.5318 7.5H9.40367L7.40367 5.5H3.75625C3.68146 5.5 3.62002 5.52404 3.57194 5.57212C3.52386 5.62021 3.49982 5.68165 3.49982 5.75644V14.2436C3.49982 14.3023 3.51451 14.3504 3.5439 14.3878C3.57327 14.4252 3.612 14.4573 3.66009 14.484L5.22898 9.16027H19.2354L17.5928 14.6731C17.4977 14.9444 17.3785 15.164 17.2354 15.3317C17.0922 15.4994 16.8684 15.5833 16.5639 15.5833H3.70817ZM4.78184 14.5H16.495L17.7963 10.2436H6.04146L4.78184 14.5Z"
      fill="currentColor"
    />
  </svg>
);

export default FolderOpen;
