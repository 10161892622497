import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';
import { type VariantProps, cva } from 'class-variance-authority';

const tileVariants = cva(
  'rounded-xl elevation-02 surface-01 h-[200px] p-1 grid grid-rows-4',
  {
    defaultVariants: {
      size: 'wide',
    },
    variants: {
      size: {
        narrow: 'w-[224px]',
        wide: 'min-w-[384px]',
      },
    },
  },
);

interface TileProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof tileVariants> {}

const Tile = forwardRef<HTMLDivElement, TileProps>(
  ({ className, size, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(tileVariants({ className, size }))}
      {...props}
    />
  ),
);

Tile.displayName = 'Tile';

export { Tile, type TileProps };
