import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const ExpandContent: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      id="Vector"
      d="M4.33325 11.6666V7.99992H5.19992V10.7999H7.99992V11.6666H4.33325ZM10.7999 7.99992V5.19992H7.99992V4.33325H11.6666V7.99992H10.7999Z"
      fill="currentColor"
    />
  </svg>
);

export default ExpandContent;
