import { Trigger as DialogTrigger } from '@radix-ui/react-dialog';

import { Dialog } from './dialog';
import { DialogContent } from './dialogContent';
import { DialogDescription } from './dialogDescription';
import { DialogFooter } from './dialogFooter';
import { DialogHeader } from './dialogHeader';
import { DialogOverlay } from './dialogOverlay';
import { DialogPortal } from './dialogPortal';
import { DialogTitle } from './dialogTitle';

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
