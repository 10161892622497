import { type HTMLAttributes, forwardRef } from 'react';

import cn from '@pxui/lib/utils';

interface TileProgressBarProps extends HTMLAttributes<HTMLDivElement> {
  progress: number;
}

const TileProgressBar = forwardRef<HTMLDivElement, TileProgressBarProps>(
  ({ className, progress, ...props }, ref) => (
    <div ref={ref} className={cn('flex gap-0.5 p-3', className)} {...props}>
      <div
        className={cn(
          'h-6 rounded-full border-2 border-solid border-tile-label-green bg-tile-label-green',
          {
            hidden: progress === 0,
          },
        )}
        style={{ width: `${progress}%` }}
      />
      <div
        className={cn(
          'h-6 rounded-full border-2 border-solid border-tile-auxiliary-orange flex-1',
          { hidden: progress === 100 },
        )}
      />
    </div>
  ),
);

TileProgressBar.displayName = 'TileProgressBar';

export { TileProgressBar, type TileProgressBarProps };
