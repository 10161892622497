/* eslint-disable sort-keys */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import flowConfig from '@flow/config';
import optimizerConfig from '@optimizer/config';
import vaultConfig from '@vault/config';

import argo from '@assets/images/launcher/argo.png';
import jupyter from '@assets/images/launcher/jupyter.png';
import jupyter_shared from '@assets/images/launcher/jupyter_shared.png';
import mlflow from '@assets/images/launcher/mlflow.png';
import vscode from '@assets/images/launcher/vscode.png';
import {
  ARGO_URL,
  JUPYTERHUB_SHARED_URL,
  JUPYTERHUB_URL,
  MLFLOW_URL,
  VSCODE_PREFIX_URL,
} from '@constants/common';

import { type AppConfig } from '@typing/AppConfig';
import { type AppRegistry } from '@typing/AppRegistry';
import { type VendorAppConfig } from '@typing/VendorAppConfig';

export const appRegistry: AppRegistry = {
  operatorApps: {
    vault: vaultConfig,
    flow: flowConfig,
    optimizer: optimizerConfig,
    jupyterLabShared: {
      appName: 'Shared JupyterLab',
      description: 'Edit code in a simple shared environment',
      external: true,
      id: 'jupyter-lab-shared',
      imageSrc: jupyter_shared,
      path: JUPYTERHUB_SHARED_URL,
      navigationLinks: [], // external app don't have any child routes
      routes: [], // external app don't have any child routes
    },
  },
  builderApps: {
    jupyterLabPrivate: {
      appName: 'Private JupyterLab',
      description: 'Code in a simple IDE to get started quickly',
      external: true,
      id: 'jupyter-lab-private',
      imageSrc: jupyter,
      path: JUPYTERHUB_URL,
    },
    vsCode: {
      appName: 'VSCode',
      description: 'Code in a feature-rich IDE you are used to',
      external: true,
      id: 'vscode',
      imageSrc: vscode,
      path: VSCODE_PREFIX_URL,
    },
    argo: {
      appName: 'Argo Workflows',
      description: 'Orchestrate cloud compute',
      external: true,
      id: 'argo',
      imageSrc: argo,
      path: ARGO_URL,
    },
    mlflow: {
      appName: 'MLFlow',
      description: 'Register models and view real-time outputs',
      external: true,
      id: 'mlflow',
      imageSrc: mlflow,
      path: MLFLOW_URL,
    },
  },
};

export const getAllOperatorApps: () => AppConfig[] = () => {
  return Object.values(appRegistry.operatorApps);
};

export const getAllBuilderApps: () => VendorAppConfig[] = () => {
  return Object.values(appRegistry.builderApps);
};

export default appRegistry;
