import { z } from 'zod';

// Blob response schema
export const FileBlobSchema = z.instanceof(Blob);

// Response schema: It can either be a boolean or a Blob for the file.
export const FetchIfNotLatestTermsConditionsResponseSchema = z.union([
  FileBlobSchema, // If the latest version is not accepted and returns a Blob
  z.record(z.string(), z.any()), // If the user has accepted latest T&Cs
]);

export type FetchIfNotLatestTermsConditionResponse = z.infer<
  typeof FetchIfNotLatestTermsConditionsResponseSchema
>;
