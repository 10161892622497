import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Schedule: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M12.9352 13.3976L13.7093 12.6234L10.541 9.45508V5.00008H9.45768V9.91987L12.9352 13.3976ZM10.0008 17.5834C8.95622 17.5834 7.97428 17.3861 7.05497 16.9913C6.13581 16.5966 5.33032 16.0535 4.63852 15.362C3.94671 14.6704 3.40331 13.8642 3.00831 12.9434C2.61345 12.0227 2.41602 11.0389 2.41602 9.99196C2.41602 8.94501 2.61338 7.96064 3.0081 7.03883C3.40282 6.11703 3.94595 5.31369 4.63747 4.62883C5.329 3.94397 6.13518 3.40404 7.05602 3.00904C7.97671 2.61418 8.96053 2.41675 10.0075 2.41675C11.0544 2.41675 12.0388 2.61578 12.9606 3.01383C13.8825 3.41189 14.6845 3.9521 15.3664 4.63446C16.0484 5.31682 16.5882 6.11925 16.986 7.04175C17.3838 7.96425 17.5827 8.94987 17.5827 9.99862C17.5827 11.0432 17.3853 12.0252 16.9906 12.9445C16.5959 13.8636 16.0562 14.6691 15.3716 15.3609C14.6871 16.0527 13.8832 16.5961 12.96 16.9911C12.0368 17.386 11.0504 17.5834 10.0008 17.5834ZM10.0093 16.5001C11.8075 16.5001 13.3388 15.8647 14.6031 14.5938C15.8673 13.323 16.4993 11.7884 16.4993 9.99008C16.4993 8.19189 15.8673 6.66064 14.6031 5.39633C13.3388 4.13217 11.8075 3.50008 10.0093 3.50008C8.21102 3.50008 6.67643 4.13217 5.4056 5.39633C4.13477 6.66064 3.49935 8.19189 3.49935 9.99008C3.49935 11.7884 4.13477 13.323 5.4056 14.5938C6.67643 15.8647 8.21102 16.5001 10.0093 16.5001Z"
      fill="currentColor"
    />
  </svg>
);

export default Schedule;
