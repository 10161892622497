import Auth0ProviderWithHistory from '@auth/Auth0ProviderWithHistory';

import { AppbarContextProvider } from '@contexts/AppbarContext';
import { Auth0TokenProvider } from '@contexts/Auth0TokenContext';

import AppWrapper from './AppWrapper';
import { AuthenticationGuard } from './AuthenticationGuard';

export const Root = () => {
  return (
    <AppbarContextProvider>
      <Auth0ProviderWithHistory>
        <AuthenticationGuard>
          <Auth0TokenProvider>
            <AppWrapper />
          </Auth0TokenProvider>
        </AuthenticationGuard>
      </Auth0ProviderWithHistory>
    </AppbarContextProvider>
  );
};

export default Root;
