type UserAvatarType = {
  userName: string;
};

const UserAvatar = ({ userName }: UserAvatarType) => {
  const [name, surname] = userName.split(' ');

  return (
    <div className="pl-1" data-hj-suppress>
      <div className="w-8 h-8 rounded-full bg-blue-450 content-center">
        <div className="flex flex-row justify-center label-1-strong text-primary pt-[3px]">
          {`${name?.[0] ?? ''}${surname?.[0] ?? ''}`}
        </div>
      </div>
    </div>
  );
};

export default UserAvatar;
