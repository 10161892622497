import React from 'react';

import { cn } from '@pxui/lib/utils';
import { Description } from '@radix-ui/react-dialog';

export const DialogDescription = React.forwardRef<
  React.ElementRef<typeof Description>,
  React.ComponentPropsWithoutRef<typeof Description>
>(({ className, ...props }, ref) => (
  <Description ref={ref} className={cn('', className)} {...props} />
));

DialogDescription.displayName = Description.displayName;

export default DialogDescription;
