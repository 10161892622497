export const Vial = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <g clipPath="url(#clip0_105_14694)">
        <path
          d="M29.9999 4C29.9999 1.79086 28.2091 0 25.9999 0H21.9999C19.7908 0 17.9999 1.79086 17.9999 4V22.2831C17.9999 23.0089 17.8025 23.721 17.4287 24.3431L6.85627 41.94C5.25447 44.606 7.17477 48 10.285 48H37.7149C40.8251 48 42.7454 44.606 41.1436 41.94L30.5712 24.3431C30.1974 23.721 29.9999 23.0089 29.9999 22.2831V4Z"
          fill="url(#paint0_linear_105_14694)"
        />
        <g filter="url(#filter0_dd_105_14694)">
          <path
            d="M15 2C15 0.895431 15.8954 0 17 0H31C32.1046 0 33 0.895431 33 2C33 3.10457 32.1046 4 31 4H17C15.8954 4 15 3.10457 15 2Z"
            fill="#F0F0F7"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_105_14694"
          x="3"
          y="-4"
          width="34"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_105_14694"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_105_14694"
            result="effect2_dropShadow_105_14694"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_105_14694"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_105_14694"
          x1="3.21533"
          y1="24"
          x2="44.7845"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1" stopColor="#AAABB2" />
          <stop offset="0.5" stopColor="#F0F0F7" />
          <stop offset="0.9" stopColor="#AAABB2" />
        </linearGradient>
        <clipPath id="clip0_105_14694">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Vial;
