/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { MouseEvent, useState, useEffect, useCallback } from 'react';

import { Button } from '@pxui/components/ui';
import { ToggleSwitch } from '@pxui/components/ui/toggleSwitch';

export enum AxisOrientation {
  'X',
  'Y',
  'Z',
}

interface ClipSettings {
  normal: { x: number; y: number; z: number };
  origin: { x: number; y: number; z: number };
  showOutline: boolean;
  showPlane: boolean;
}

export interface ClipPropertiesPanelProps {
  onClipSettingsChange: (settings: ClipSettings) => void;
  onSetClipPlaneNormal: (x: number, y: number, z: number) => void;
  onToggleClipOutlineVisibility: (show: boolean) => void;
  onToggleClipPlaneVisibility: (show: boolean) => void;
  triggerClip: () => void;
}

export const ClipPropertiesPanel: React.FC<ClipPropertiesPanelProps> = ({
  onClipSettingsChange,
  onToggleClipPlaneVisibility,
  onToggleClipOutlineVisibility,
  onSetClipPlaneNormal,
  triggerClip,
}) => {
  const [activeAxis, setActiveAxis] = useState<AxisOrientation>(
    AxisOrientation.Z,
  );
  const [showPlane, setShowPlane] = useState<boolean>(true);
  const [showOutline, setShowOutline] = useState<boolean>(true);
  const [origin] = useState<{ x: number; y: number; z: number }>({
    x: 0,
    y: 0,
    z: 0,
  });
  const [normal, setNormal] = useState<{ x: number; y: number; z: number }>({
    x: 1,
    y: 0,
    z: 0,
  });

  // Update clipping settings whenever state changes
  useEffect(() => {
    onClipSettingsChange({ normal, origin, showOutline, showPlane });
  }, [showPlane, showOutline, origin, normal, onClipSettingsChange]);

  const handleShowPlaneChange = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const isChecked =
        (e.target as HTMLButtonElement).dataset.state === 'checked';
      onToggleClipPlaneVisibility(!isChecked);
      setShowPlane(!isChecked);
    },
    [onToggleClipPlaneVisibility],
  );

  const handleShowOutlineChange = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const isChecked =
        (e.target as HTMLButtonElement).dataset.state === 'checked';
      onToggleClipOutlineVisibility(!isChecked);
      setShowOutline(!isChecked);
    },
    [onToggleClipOutlineVisibility],
  );

  const handleNormalChange = useCallback(
    (x: number, y: number, z: number) => {
      onSetClipPlaneNormal(x, y, z);
      setNormal({ x, y, z });
    },
    [onSetClipPlaneNormal, setNormal],
  );

  const setNormalToX = () => {
    setActiveAxis(AxisOrientation.X);
    handleNormalChange(1, 0, 0);
  };

  const setNormalToY = () => {
    setActiveAxis(AxisOrientation.Y);
    handleNormalChange(0, 1, 0);
  };

  const setNormalToZ = () => {
    setActiveAxis(AxisOrientation.Z);
    handleNormalChange(0, 0, 1);
  };

  return (
    <div className="text-primary text-sm">
      <div className="flex justify-between items-center mb-5">
        <span className="label-1-strong">Axis</span>
        <div className="flex">
          <Button
            className="py-1 px-2 rounded-none"
            onClick={setNormalToX}
            layout="textOnly"
            variant={activeAxis === AxisOrientation.X ? 'primary' : 'secondary'}
          >
            X
          </Button>
          <Button
            className="py-1 px-2 rounded-none"
            onClick={setNormalToY}
            layout="textOnly"
            variant={activeAxis === AxisOrientation.Y ? 'primary' : 'secondary'}
          >
            Y
          </Button>
          <Button
            className="py-1 px-2 rounded-none"
            onClick={setNormalToZ}
            layout="textOnly"
            variant={activeAxis === AxisOrientation.Z ? 'primary' : 'secondary'}
          >
            Z
          </Button>
        </div>
      </div>

      <div className="flex mb-5 justify-between">
        <label className="flex justify-between basis-2/5">
          <span className="label-1-strong">Show Plane</span>
          <ToggleSwitch checked={showPlane} onClick={handleShowPlaneChange} />
        </label>
        <label className="flex justify-between basis-2/5">
          <span className="label-1-strong">Show Outline</span>
          <ToggleSwitch
            checked={showOutline}
            onClick={handleShowOutlineChange}
          />
        </label>
      </div>

      <div className="flex mb-5 justify-end">
        <Button layout="textOnly" onClick={triggerClip} className="w-28">
          Clip
        </Button>
      </div>
    </div>
  );
};

export default ClipPropertiesPanel;
