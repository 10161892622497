import { type HTMLAttributes, forwardRef } from 'react';

import cn from '@pxui/lib/utils';

export const ButtonGroup = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'flex w-fit [&_button]:rounded-none first:[&_button]:rounded-s last:[&_button]:rounded-e',
        className,
      )}
    >
      {children}
    </div>
  );
});

ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;
