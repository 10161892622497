import { type HTMLAttributes, forwardRef } from 'react';

import cn from '@pxui/lib/utils';

export const TileNumberLabel = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('px-3 text-tile-label-green display-1', className)}
    {...props}
  >
    {children}
  </p>
));

TileNumberLabel.displayName = 'TileNumberLabel';

export default TileNumberLabel;
