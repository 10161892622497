import {
  type ComponentPropsWithoutRef,
  type FC,
  useState,
  useEffect,
} from 'react';

import {
  CheckedIcon,
  IndeterminateIcon,
  UncheckedIcon,
} from '@pxui/components/ui/icons';
import { cn } from '@pxui/lib/utils';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

interface CheckboxProps
  extends ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  indeterminate?: boolean;
}

const layoutClasses = 'inline-flex items-center cursor-pointer';
const disabledClasses = 'disabled:cursor-not-allowed disabled:opacity-50';
const interactionClasses = 'focus-visible:outline-none';
const spacingClasses = 'p-[3px]';

export const Checkbox: FC<CheckboxProps> = ({
  className,
  checked,
  indeterminate,
  onCheckedChange,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const checkedIcon = indeterminate ? <IndeterminateIcon /> : <CheckedIcon />;

  const handleCheckedChange = (checkedValue: boolean) => {
    setIsChecked(() => {
      if (onCheckedChange) {
        onCheckedChange(checkedValue);
      }
      return checkedValue;
    });
  };

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked);
    }
  }, [checked, isChecked]);

  return (
    <CheckboxPrimitive.Root
      checked={isChecked}
      onCheckedChange={handleCheckedChange}
      className={cn(
        layoutClasses,
        disabledClasses,
        interactionClasses,
        spacingClasses,
        className,
      )}
      {...props}
    >
      {isChecked ? checkedIcon : <UncheckedIcon />}
    </CheckboxPrimitive.Root>
  );
};

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export default Checkbox;
