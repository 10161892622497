import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const BidLandscape: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.75625 16.5833C4.39087 16.5833 4.07623 16.4514 3.81234 16.1875C3.54845 15.9236 3.4165 15.609 3.4165 15.2436V4.75644C3.4165 4.38133 3.54845 4.06426 3.81234 3.80523C4.07623 3.5462 4.39087 3.41669 4.75625 3.41669H15.2434C15.6185 3.41669 15.9356 3.5462 16.1946 3.80523C16.4536 4.06426 16.5831 4.38133 16.5831 4.75644V15.2436C16.5831 15.609 16.4536 15.9236 16.1946 16.1875C15.9356 16.4514 15.6185 16.5833 15.2434 16.5833H4.75625ZM4.49982 13.4663V15.2436C4.49982 15.3077 4.52652 15.3664 4.57994 15.4199C4.63337 15.4733 4.69214 15.5 4.75625 15.5H15.2434C15.3075 15.5 15.3663 15.4733 15.4197 15.4199C15.4731 15.3664 15.4998 15.3077 15.4998 15.2436V7.35254L10.1008 12.875L7.59598 10.3702L4.49982 13.4663ZM4.49982 11.9551L7.59598 8.85898L10.112 11.3542L15.4998 5.83175V4.75644C15.4998 4.69233 15.4731 4.63356 15.4197 4.58013C15.3663 4.52671 15.3075 4.5 15.2434 4.5H4.75625C4.69214 4.5 4.63337 4.52671 4.57994 4.58013C4.52652 4.63356 4.49982 4.69233 4.49982 4.75644V11.9551ZM4.49982 8.31087V6.66508V11.875V8.73398V13.4663V10.2452V13.3541V8.31087ZM4.49982 11.9551V4.5V11.875V8.73398V11.9551ZM4.49982 13.4663V10.2452V13.3541V8.31087V15.5V13.4663Z"
      fill="currentColor"
    />
  </svg>
);

export default BidLandscape;
