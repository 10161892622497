const Checked = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <rect className="fill-icon-primary" x="1" y="1" width="12" height="12" />
    <path
      className="fill-checkbox-primary"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.413134 13.5869C0.688557 13.8623 1.0257 14 1.42455 14H12.5754C12.9743 14 13.3114 13.8623 13.5869 13.5869C13.8623 13.3114 14 12.9743 14 12.5755V1.42455C14 1.0257 13.8623 0.688558 13.5869 0.413135C13.3114 0.137712 12.9743 0 12.5754 0H1.42455C1.0257 0 0.688557 0.137712 0.413134 0.413135C0.137711 0.688558 0 1.0257 0 1.42455V12.5755C0 12.9743 0.137711 13.3114 0.413134 13.5869ZM6.42426 9.42426L10.9243 4.92426L10.0757 4.07574L6 8.15147L3.92426 6.07574L3.07574 6.92426L5.57574 9.42426L6 9.84853L6.42426 9.42426Z"
    />
  </svg>
);

export default Checked;
