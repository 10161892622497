import { type HTMLAttributes, createElement, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

interface DetailsListProps
  extends HTMLAttributes<HTMLUListElement | HTMLOListElement> {
  type?: 'ordered' | 'unordered';
}

const DetailsList = forwardRef<
  HTMLUListElement | HTMLOListElement,
  DetailsListProps
>(({ className, children, type = 'unordered', ...props }, ref) =>
  createElement(
    type === 'unordered' ? 'ul' : 'ol',
    {
      className: cn(
        'flex flex-col w-full py-[5px] px-4 justify-between items-start text-secondary label-1 list-disc',
        className,
      ),
      ref,
      ...props,
    },
    children,
  ),
);

DetailsList.displayName = 'DetailsList';

export { DetailsList, type DetailsListProps };
