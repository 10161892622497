import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
} from 'react';

import { cn } from '@pxui/lib/utils';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { cva } from 'class-variance-authority';
import { ChevronDown } from 'lucide-react';

const layoutClasses =
  'group relative inline-flex items-center justify-center rounded border border-transparent';

const interactionClasses =
  'hover:state-hover active:state-active focus-visible:outline-none focus-visible:state-focus focus-visible:state-focus-inset';

const spacingClasses = 'px-3 py-[7px]';

const transitionClasses = 'transition-colors duration-150 ease-out';

const navigationMenuTriggerStyle = cva(
  cn(
    'cursor-pointer label-1 text-secondary',
    layoutClasses,
    interactionClasses,
    spacingClasses,
    transitionClasses,
  ),
);

const NavigationMenuTrigger = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Trigger
    ref={ref}
    className={cn(navigationMenuTriggerStyle(), 'group', className)}
    {...props}
  >
    {children}

    <ChevronDown
      className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
      aria-hidden="true"
    />
  </NavigationMenuPrimitive.Trigger>
));

NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;

export { NavigationMenuTrigger, navigationMenuTriggerStyle };
