import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
} from 'react';

import cn from '@pxui/lib/utils';
import * as ProgressPrimitive from '@radix-ui/react-progress';
import { type VariantProps, cva } from 'class-variance-authority';

const progressIndicatorVariants = cva('h-full w-full flex-1 transition-all', {
  defaultVariants: {
    variant: 'active',
  },
  variants: {
    variant: {
      active: 'bg-checkbox-primary',
      error: 'bg-auxiliary-error',
      success: 'bg-auxiliary-success',
    },
  },
});

interface ProgressBarProps
  extends ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>,
    VariantProps<typeof progressIndicatorVariants> {}

const ProgressBar = forwardRef<
  ElementRef<typeof ProgressPrimitive.Root>,
  ProgressBarProps
>(({ className, value, variant, ...props }, ref) => {
  return (
    <ProgressPrimitive.Root
      ref={ref}
      className={cn(
        'relative h-3 w-full overflow-hidden rounded-sm surface-00',
        className,
      )}
      {...props}
    >
      <ProgressPrimitive.Indicator
        className={cn(progressIndicatorVariants({ variant }))}
        style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
      />
    </ProgressPrimitive.Root>
  );
});

ProgressBar.displayName = 'ProgressBar';

export { ProgressBar, progressIndicatorVariants, type ProgressBarProps };
