import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
  AuthorizationParams,
} from '@auth0/auth0-react';

/**
 * Auth0ProviderWithHistory component integrates Auth0 with React Router history.
 * It provides the Auth0 context to its children and handles redirection after login.
 *
 * @param {object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components.
 *
 * @returns {JSX.Element} The Auth0Provider component with the provided settings.
 */
const Auth0ProviderWithHistory: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const domain = process.env.PLATFORM_AUTH0_DOMAIN || '';
  const clientId = process.env.PLATFORM_AUTH0_CLIENT_ID || '';
  const audience = process.env.PLATFORM_AUTH0_AUDIENCE;
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Handles the redirection after login.
   *
   * @param {AppState} [appState] - The state returned by Auth0 on redirect.
   */
  const onRedirectCallback = (appState?: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const authorizationParams: AuthorizationParams = {
    audience,
    redirect_uri: `${window.location.origin}/oauth2/callback`,
  };

  const searchParams = new URLSearchParams(location.search);
  const inviteParam = searchParams.get('invitation');
  const connectionParam = searchParams.get('connection');
  const organizationParam = process.env.PLATFORM_NAMESPACE;

  if (inviteParam) {
    authorizationParams.invitation = inviteParam;
  }

  if (organizationParam) {
    authorizationParams.organization = organizationParam;
  }

  if (connectionParam) {
    authorizationParams.connection = connectionParam;
  }

  const auth0ProviderProps: Auth0ProviderOptions = {
    authorizationParams,
    /* IMPORTANT:
    Storing tokens in browser local storage provides persistence across page refreshes and browser tabs.
    However, if an attacker can achieve running JavaScript in the SPA using a cross-site scripting (XSS) attack,
    they can retrieve the tokens stored in local storage. A vulnerability leading to a successful XSS attack
    can be either in the SPA source code or in any third-party JavaScript code (such as bootstrap, jQuery,
    or Google Analytics) included in the SPA.
    */
    cacheLocation: 'localstorage',
    clientId,
    domain,
    onRedirectCallback,
    // IMPORTANT: Refresh Token is bound to the particular audience and scope that was requested at user authentication time.
    useRefreshTokens: true,
  };

  return <Auth0Provider {...auth0ProviderProps}>{children}</Auth0Provider>;
};

export default Auth0ProviderWithHistory;
