import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const PrivacyTip: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.45817 13.6794H10.5415V8.82369H9.45817V13.6794ZM9.99542 7.49998C10.1613 7.49998 10.3018 7.44388 10.4169 7.33169C10.532 7.21949 10.5895 7.08047 10.5895 6.91463C10.5895 6.74878 10.5334 6.6083 10.4213 6.49319C10.3091 6.37808 10.1701 6.32052 10.0042 6.32052C9.83835 6.32052 9.69786 6.37662 9.58275 6.48881C9.46764 6.60099 9.41009 6.74001 9.41009 6.90586C9.41009 7.0717 9.46618 7.21219 9.57838 7.32731C9.69056 7.44242 9.82957 7.49998 9.99542 7.49998ZM9.99982 17.5673C8.25302 17.1036 6.80244 16.0889 5.64807 14.5233C4.49369 12.9576 3.9165 11.2068 3.9165 9.27081V4.78846L9.99982 2.44873L16.0831 4.78846V9.27081C16.0831 11.2068 15.5059 12.9576 14.3516 14.5233C13.1972 16.0889 11.7466 17.1036 9.99982 17.5673ZM9.99982 16.4375C11.4443 15.9896 12.6387 15.0937 13.5831 13.75C14.5276 12.4062 14.9998 10.9132 14.9998 9.27081V5.52081L9.99982 3.61217L4.99982 5.52081V9.27081C4.99982 10.9132 5.47204 12.4062 6.41648 13.75C7.36093 15.0937 8.55537 15.9896 9.99982 16.4375Z"
      fill="currentColor"
    />
  </svg>
);

export default PrivacyTip;
