import type { FC } from 'react';

import { Button } from '@pxui/components/ui/button';
import moment from 'moment';

interface ExportButtonProps {
  count: number;
  fileContent: BlobPart;
  fileName: string;
  fileType?: string;
  withTimestamp?: boolean;
}

const ExportButton: FC<ExportButtonProps> = ({
  fileContent,
  fileName,
  withTimestamp,
  fileType = 'text/plain;charset=utf-8',
  count,
}) => {
  const handleDownload = () => {
    const element = document.createElement('a');
    const file = new Blob([fileContent], { type: fileType });

    element.href = URL.createObjectURL(file);
    element.download = fileName;

    if (withTimestamp) {
      const formattedDate = moment().format('DD-MM-YYYY_HH:mm:ss');
      element.download += `-${formattedDate}`;
    }

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Button layout="textOnly" variant="secondary" onClick={handleDownload}>
      Export ({count})
    </Button>
  );
};

export default ExportButton;
