import type { FC, MouseEventHandler } from 'react';

import {
  ViewItemContent,
  type ViewItemContentProps,
} from '@pxui/components/ui/view';

interface ListViewItemProps
  extends Omit<ViewItemContentProps, 'caret' | 'nestLevel'> {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const ListViewItem: FC<ListViewItemProps> = ({
  icon,
  label,
  selected,
  textRight,
  onClick,
}) => (
  <button
    type="button"
    onClick={onClick}
    className="px-1.5 w-full h-9 flex min-w-[280px]"
  >
    <ViewItemContent
      icon={icon}
      label={label}
      selected={selected}
      textRight={textRight}
    />
  </button>
);

export { ListViewItem, type ListViewItemProps };
