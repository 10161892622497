import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
} from 'react';

import { CheckIcon } from '@pxui/components/ui/icons';
import { cn } from '@pxui/lib/utils';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

const layoutClasses = 'relative flex items-center outline-none';

const disabledClasses =
  'data-[disabled]:pointer-events-none data-[disabled]:opacity-50';

export const DropdownMenuCheckboxItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={ref}
    className={cn(
      'label-1 text-secondary cursor-pointer w-[260px] select-none hover:state-hover transition-colors',
      layoutClasses,
      disabledClasses,
      className,
    )}
    checked={checked}
    {...props}
  >
    <span className="flex h-8 w-8 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <CheckIcon className="text-icon-primary fill-icon-primary" />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.CheckboxItem>
));

DropdownMenuCheckboxItem.displayName =
  DropdownMenuPrimitive.CheckboxItem.displayName;

export default DropdownMenuCheckboxItem;
