const AppPlaceholder = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6.4C0 4.15979 0 3.03969 0.435974 2.18404C0.819467 1.43139 1.43139 0.819467 2.18404 0.435974C3.03969 0 4.15979 0 6.4 0H21.6C23.8402 0 24.9603 0 25.816 0.435974C26.5686 0.819467 27.1805 1.43139 27.564 2.18404C28 3.03969 28 4.15979 28 6.4V21.6C28 23.8402 28 24.9603 27.564 25.816C27.1805 26.5686 26.5686 27.1805 25.816 27.564C24.9603 28 23.8402 28 21.6 28H6.4C4.15979 28 3.03969 28 2.18404 27.564C1.43139 27.1805 0.819467 26.5686 0.435974 25.816C0 24.9603 0 23.8402 0 21.6V6.4Z"
      fill="#C6C6C6"
    />
  </svg>
);

export default AppPlaceholder;
