import {
  type HTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Link } from '@pxui/components/ui/link';
import { cn } from '@pxui/lib/utils';

interface DetailsLinkProps extends HTMLAttributes<HTMLDivElement> {
  asButton?: boolean;
  button?: React.ReactElement;
  label?: string;
  linkLabel?: string;
  linkTo?: string;
}

const DetailsLink = forwardRef<HTMLDivElement, DetailsLinkProps>(
  (
    { className, label, linkLabel, linkTo, asButton, button, ...props },
    ref,
  ) => {
    const labelRef = useRef<HTMLSpanElement>(null);
    const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

    // Check if the label width exceeds 125px
    useEffect(() => {
      const labelElement = labelRef.current;
      if (labelElement) {
        const { width } = labelElement.getBoundingClientRect();
        // eslint-disable-next-line no-magic-numbers
        setShouldAnimate(width > 125);
      }
    }, [label]);

    return (
      <div
        ref={ref}
        className={cn(
          'flex w-full py-[5px] px-4 justify-between items-start items-center',
          className,
        )}
        {...props}
      >
        <div className="relative max-w-[170px] overflow-x-hidden cursor-pointer">
          <div className={cn(shouldAnimate ? 'hover:animate-slide' : '')}>
            <span
              ref={labelRef}
              className="pb-1 label-1 text-secondary hover:bg-gray-800"
            >
              {label}
            </span>
          </div>
        </div>
        <div className="min-w-[60px] max-w-[125px]">
          {asButton ? button : <Link href={linkTo}>{linkLabel}</Link>}
        </div>
      </div>
    );
  },
);
DetailsLink.displayName = 'DetailsLink';

export { DetailsLink, type DetailsLinkProps };
