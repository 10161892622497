import axios, {
  AxiosError,
  AxiosResponse,
  HttpStatusCode,
  InternalAxiosRequestConfig,
} from 'axios';

import {
  HTTP_STATUS_BAD_REQUEST,
  HTTP_STATUS_OK,
} from '@constants/httpStatusCodes';

interface RetryConfig extends InternalAxiosRequestConfig {
  retry: boolean;
}

interface RetryAxiosError extends AxiosError {
  config: RetryConfig;
}

// Create an instance of axios
const apiClient = axios.create();

/**
 * Update access token in the axios client default and the current request
 *
 * @param {InternalAxiosRequestConfig} config
 * @param {string} newToken
 * @returns {InternalAxiosRequestConfig}
 */
export const updateAccessTokenInHeaders = (
  config: InternalAxiosRequestConfig,
  newToken: string,
) => {
  // Update the default headers and original request with the new token
  apiClient.defaults.headers.common.Authorization = `Bearer ${newToken}`;

  // Clone and modify the request configuration to include the Authorization header
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${newToken}`,
    },
  } as InternalAxiosRequestConfig;
};

/**
 * Sets up interceptors for the axios instance to manage access tokens.
 *
 * @param {string | undefined} accessToken - The initial access token, if available.
 * @param {() => Promise<string | undefined>} fetchAccessToken - A function to fetch a new access token.
 * @param {() => void} loginWithRedirect - A function to redirect to the login page.
 */
export const setupInterceptors = (
  accessToken: string | undefined,
  fetchAccessToken: () => Promise<string | undefined>,
  loginWithRedirect: () => void,
) => {
  /**
   * Request interceptor to add the Authorization header to outgoing requests.
   *
   * @param {InternalAxiosRequestConfig} config - The Axios request configuration.
   * @returns {Promise<InternalAxiosRequestConfig>} The modified request configuration with the Authorization header.
   */
  apiClient.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      try {
        // Get the access token, either from the parameter or by fetching a new one
        const token = accessToken || (await fetchAccessToken());

        if (!token) {
          throw new Error('No access token found');
        }

        if (!config.headers.Authorization) {
          //  probably a first fetch and access token not added to headers yet
          return updateAccessTokenInHeaders(config, token);
        }

        //  headers already have an access token, nothing to do here
        return config;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching access token', { error });

        // Return the original config if there is an error fetching the token
        return config;
      }
    },
    async (error: AxiosError) => {
      return Promise.reject(error);
    },
  );

  /**
   * Response interceptor to handle errors related to authentication.
   *
   * @param {AxiosResponse} response - The Axios response.
   * @returns {Promise<AxiosResponse>} The response if there is no error.
   */
  apiClient.interceptors.response.use(
    async (response: AxiosResponse) => {
      return response;
    },
    async (responseError: RetryAxiosError) => {
      const originalRequest = responseError.config;

      // Check if the error status is UNAUTHORIZED or FORBIDDEN
      if (
        responseError.response?.status === HttpStatusCode.Unauthorized ||
        responseError.response?.status === HttpStatusCode.Forbidden
      ) {
        // Check if the original request has already been retried
        if (originalRequest && !originalRequest.retry) {
          originalRequest.retry = true;

          try {
            // Fetch a new access token
            const newToken = await fetchAccessToken();

            if (!newToken) {
              throw new Error('No access token found');
            }

            const updatedRequest = updateAccessTokenInHeaders(
              originalRequest,
              newToken,
            );

            // Retry the original request with the new token only once
            return await apiClient(updatedRequest);
          } catch (retryError: any) {
            // eslint-disable-next-line no-console
            console.error(
              `Error fetching access token or during re-request: ${responseError?.message || responseError.toString()} ${retryError?.message || retryError.toString()}`,
            );
          }
        } else {
          // If the request has already been retried, redirect to login
          loginWithRedirect();
        }
      }

      if (responseError.response?.status === HttpStatusCode.Conflict) {
        //  propagate the original axios error so we can
        //  react appropriately downstream in the UI
        return Promise.reject(responseError);
      }

      if (
        responseError.response &&
        responseError.response?.status >= HTTP_STATUS_OK &&
        responseError.response?.status < HTTP_STATUS_BAD_REQUEST
      ) {
        // If the response status is in the 2xx to 3xx range,
        // return the response so it can be handled downstream
        return Promise.resolve(responseError);
      }

      // Reject the promise with a new error if there was a network error or the server did not respond
      return Promise.reject(
        new Error(
          responseError.message || 'Network error or server did not respond.',
        ),
      );
    },
  );
};

export default apiClient;
