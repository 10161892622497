import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Placeholder: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4H16V16H4V4ZM2 2H4H16H18V4V16V18H16H4H2V16V4V2ZM13 7H7V13H13V7Z"
      fill="currentColor"
    />
  </svg>
);

export default Placeholder;
