import { toast } from '@pxui/components/ui/toast/useToast';

import fetchLatestFluxCLI from '@services/fetchLatestFluxCLI';

/**
 * Custom hook to handle the one-click download of the latest Flux CLI package.
 *
 * This hook fetches the latest Flux CLI package from the server and automatically
 * triggers the download when the user clicks the download button. The filename is
 * extracted from the `Content-Disposition` header if available, otherwise a default
 * name is used.
 *
 * If an error occurs during the fetch or download process, a toast notification is triggered
 * to notify the user.
 *
 * @returns {{ handleDownloadClick: () => Promise<void> }} - An object containing:
 *  - `handleDownloadClick`: A function to be called on button click to fetch and download the Flux CLI zip file.
 * @example
 * const { handleDownloadClick } = useLatestFluxCLI();
 * return (
 *   <button onClick={handleDownloadClick}>
 *     Download Flux CLI
 *   </button>
 * );
 */

const useLatestFluxCLI = (): {
  handleFluxCLIDownloadClick: () => Promise<void>;
} => {
  const handleFluxCLIDownloadClick = async () => {
    try {
      const response = await fetchLatestFluxCLI();

      // Check if response and headers are valid
      if (!response || !response.headers) {
        console.error('Invalid response or headers are missing');
        toast({
          description:
            'Unable to fetch the download link. Please try again later.',
          title: 'Download error',
          variant: 'error',
        });
        return;
      }

      // Extract filename from Content-Disposition header
      let fileName = 'flux_cli.zip';
      const contentDisposition = response.headers['content-disposition'];
      if (contentDisposition) {
        const matches = /filename="?([^";]+)"?/.exec(contentDisposition);
        if (matches?.[1]) {
          fileName = matches?.[1];
        }
      }
      // create download url
      const blob = await response.data;
      const downloadUrl = window.URL.createObjectURL(blob);

      // Create an invisible anchor element and trigger the download
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Revoke the object URL after some time to release memory
      setTimeout(() => window.URL.revokeObjectURL(downloadUrl), 100);
    } catch (error) {
      console.error('Error fetching Flux CLI:', error);
      toast({
        description: 'Please try again or contact us if the issue persists.',
        title: 'Error downloading Flux CLI',
        variant: 'error',
      });
    }
  };

  return { handleFluxCLIDownloadClick };
};

export default useLatestFluxCLI;
