import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Play: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.41663 14.7467V5.25336L14.7306 10L7.41663 14.7467ZM8.49996 12.75L12.766 10L8.49996 7.25002V12.75Z"
      fill="currentColor"
    />
  </svg>
);

export default Play;
