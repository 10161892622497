import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const DownArrowAlt: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10 13.7115L6.28857 10L7.06253 9.22604L9.45837 11.6219V5.20833H10.5417V11.6219L12.9375 9.22604L13.7115 10L10 13.7115Z"
      fill="currentColor"
    />
  </svg>
);

export default DownArrowAlt;
