import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

const layoutClasses = 'border-b';
const colorClasses = 'border-00-subtle';

export const TableRow = forwardRef<
  HTMLTableRowElement,
  HTMLAttributes<HTMLTableRowElement> & { isSelected?: boolean }
>(({ className, isSelected, ...props }, ref) => {
  const interactionClasses = isSelected
    ? 'state-selected' // Apply 'state-selected' class when selected, no hover
    : 'hover:state-hover'; // Apply hover state when not selected

  return (
    <tr
      ref={ref}
      className={cn(layoutClasses, colorClasses, interactionClasses, className)}
      {...props}
    />
  );
});

TableRow.displayName = 'TableRow';

export default TableRow;
