import { Dialog, DialogContent } from '@pxui/components/ui/dialog';
import cn from '@pxui/lib/utils';
import { type DialogProps } from '@radix-ui/react-dialog';

import { Command } from './command';

export interface CommandDialogProps extends DialogProps {
  className?: string;
  testId?: string;
}

export const CommandDialog = ({
  children,
  testId,
  ...props
}: CommandDialogProps) => {
  return (
    <Dialog {...props}>
      <DialogContent
        className={cn(
          'overflow-hidden p-0 w-full h-full',
          props.className ?? '',
        )}
        data-testid={testId}
      >
        <Command>{children}</Command>
      </DialogContent>
    </Dialog>
  );
};

export default CommandDialog;
