import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Visibility: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <mask
      id="mask0_656_4264"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <rect width="20" height="20" fill="#fff" />
    </mask>
    <g mask="url(#mask0_656_4264)">
      <path
        d="M10.0019 13.1475C10.8767 13.1475 11.6197 12.8413 12.2308 12.229C12.8419 11.6166 13.1475 10.873 13.1475 9.99815C13.1475 9.12328 12.8413 8.3803 12.2289 7.76919C11.6166 7.15808 10.873 6.85252 9.99811 6.85252C9.12324 6.85252 8.38026 7.1587 7.76915 7.77106C7.15804 8.38342 6.85248 9.12703 6.85248 10.0019C6.85248 10.8768 7.15866 11.6197 7.77102 12.2309C8.38338 12.842 9.12699 13.1475 10.0019 13.1475ZM9.99998 12C9.44443 12 8.9722 11.8056 8.58331 11.4167C8.19443 11.0278 7.99998 10.5556 7.99998 10C7.99998 9.44446 8.19443 8.97224 8.58331 8.58335C8.9722 8.19447 9.44443 8.00002 9.99998 8.00002C10.5555 8.00002 11.0278 8.19447 11.4166 8.58335C11.8055 8.97224 12 9.44446 12 10C12 10.5556 11.8055 11.0278 11.4166 11.4167C11.0278 11.8056 10.5555 12 9.99998 12ZM10.001 15.5834C8.13199 15.5834 6.42901 15.0756 4.89206 14.06C3.35512 13.0446 2.20734 11.6913 1.44873 10C2.20734 8.30877 3.3547 6.95544 4.89081 5.94002C6.42706 4.92446 8.12977 4.41669 9.99894 4.41669C11.868 4.41669 13.571 4.92446 15.1079 5.94002C16.6448 6.95544 17.7926 8.30877 18.5512 10C17.7926 11.6913 16.6453 13.0446 15.1091 14.06C13.5729 15.0756 11.8702 15.5834 10.001 15.5834ZM9.99998 14.5C11.5555 14.5 12.993 14.0972 14.3125 13.2917C15.6319 12.4861 16.6458 11.3889 17.3541 10C16.6458 8.61113 15.6319 7.51391 14.3125 6.70835C12.993 5.9028 11.5555 5.50002 9.99998 5.50002C8.44443 5.50002 7.00692 5.9028 5.68748 6.70835C4.36804 7.51391 3.35415 8.61113 2.64581 10C3.35415 11.3889 4.36804 12.4861 5.68748 13.2917C7.00692 14.0972 8.44443 14.5 9.99998 14.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Visibility;
