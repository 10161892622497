import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const MLFlow: FC<IconProps> = ({ className, size = 'default', id }) => (
  <svg
    id={id}
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.92201 0.00082754C6.42631 0.0208054 4.96593 0.458202 3.7055 1.26371C2.44508 2.06921 1.43472 3.2108 0.788344 4.55976C0.141966 5.90873 -0.114734 7.41144 0.0471822 8.89848C0.209099 10.3855 0.783194 11.7978 1.70475 12.976L4.15005 11.18C3.55585 10.4418 3.18142 9.55124 3.06959 8.61021C2.95776 7.66917 3.11305 6.71566 3.51769 5.85873C3.92234 5.00181 4.55999 4.27607 5.35773 3.76453C6.15547 3.253 7.08107 2.97631 8.02868 2.96613V4.87744L12.634 1.45081C11.2549 0.484001 9.60606 -0.0233787 7.92201 0.00082754ZM14.2966 3.02279L11.8513 4.81944C12.4457 5.55766 12.8203 6.44829 12.9322 7.38943C13.0442 8.33057 12.8889 9.28421 12.4843 10.1413C12.0796 10.9983 11.4419 11.7241 10.644 12.2357C9.84614 12.7473 8.9204 13.0239 7.97268 13.034V11.1227L3.3674 14.5493C4.94403 15.6518 6.86406 16.1517 8.77814 15.9579C10.6922 15.7642 12.4732 14.8897 13.7969 13.4936C15.1206 12.0976 15.8991 10.2727 15.9909 8.35101C16.0826 6.42933 15.4814 4.53858 14.2966 3.02279Z"
      fill="currentColor"
    />
  </svg>
);

export default MLFlow;
