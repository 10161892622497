import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

export const TableHeader = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'py-2 px-4 w-full flex justify-between items-center',
      className,
    )}
    {...props}
  >
    {children}
  </div>
));

TableHeader.displayName = 'TableHeader';

export default TableHeader;
