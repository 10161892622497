import { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { toast } from '@pxui/components/ui/toast/useToast';
import { AxiosResponse } from 'axios';
import { useSetRecoilState } from 'recoil';

import { termsAndConditionsState } from '@states/latestTermsAndConditions';

import fetchIfNotLatestTermsConditions from '@services/fetchIfNotLatestTermsConditions';
import fetchLatestTermsConditions from '@services/fetchLatestTermsConditions';

/**
 * Custom hook to handle fetching the latest Terms and Conditions (T&Cs) and ensuring
 * that they are set in the app state, even if the user has already accepted them.
 *
 * This hook checks if the user has accepted the latest T&Cs. If they have accepted,
 * the T&C modal will not be displayed, but the latest T&Cs PDF is still fetched to
 * set in the app state, as it is required for the application. Additionally, a new
 * access token is fetched after confirming the latest T&Cs.
 *
 * If the user has not accepted the latest T&Cs, the appropriate state is set to
 * prompt the user to accept the latest version via a modal.
 *
 * The hook also manages the loading and error states during the fetching process. In case of an error,
 * a toast notification is triggered to notify the user of the issue.
 *
 * @returns {{
 *  acceptedLatestTerms: boolean,  // Whether the user has accepted the latest T&Cs
 *  isError: boolean,              // Whether an error occurred during the fetch process
 *  isLoading: boolean,            // Whether the fetch process is ongoing
 *  setAcceptedLatestTerms: (boolean) => void  // Function to manually set the accepted status
 * }}
 *
 * @example
 * const { acceptedLatestTerms, isError, isLoading, setAcceptedLatestTerms } = useFetchIfNotLatestTermsConditions();
 *
 * if (isLoading) return <Spinner />;
 * if (isError) return <ErrorNotification />;
 * if (!acceptedLatestTerms) return <TermsAndConditionsModal />;
 */

const extractTermsConditionsDataFromBlob = async (
  blobResponse: AxiosResponse<Blob>,
) => {
  // Extract filename from Content-Disposition header
  let fileName = 'PhysicsX End User Licence Agreement.pdf';
  const contentDisposition = blobResponse.headers['content-disposition'];
  if (contentDisposition) {
    const matches = /filename="?([^";]+)"?/.exec(contentDisposition);
    if (matches?.[1]) {
      fileName = matches?.[1];
    }
  }

  const version = blobResponse.headers.terms_conditions_version ?? 0;
  const prevAcceptedVersion =
    blobResponse.headers.terms_conditions_latest_accepted_version ?? null;
  const blob = await blobResponse.data;
  // create download url
  const url = window.URL.createObjectURL(blob);

  return { fileName, prevAcceptedVersion, url, version };
};

// TODO: add logic for checking what version has the person agreed to for re-accept flow
const useFetchIfNotLatestTermsConditions = () => {
  const setTermsAndConditions = useSetRecoilState(termsAndConditionsState);
  const { getAccessTokenSilently } = useAuth0();

  const [acceptedLatestTerms, setAcceptedLatestTerms] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const checkIfNotAcceptedLatestTCs = async () => {
      try {
        const response = await fetchIfNotLatestTermsConditions();

        // Check if response and headers are valid
        if (!response?.data || !response?.headers) {
          setIsError(true);
          setIsLoading(false);
          throw new Error('Invalid response or headers are missing');
        }

        // if user has accepted latest T&Cs no need for modal, proceed to the app
        if (!(response?.data instanceof Blob)) {
          setAcceptedLatestTerms(true);
          setIsLoading(false);
          // still need to fetch the T&Cs for userProfile even if user accepted the latest
          const latestTerms = await fetchLatestTermsConditions();
          const { fileName, version, url, prevAcceptedVersion } =
            await extractTermsConditionsDataFromBlob(latestTerms);
          setTermsAndConditions({
            currentVersion: version,
            fileName,
            prevAcceptedVersion,
            url,
          });
          await getAccessTokenSilently({ cacheMode: 'off' });
          return;
        }

        const { fileName, version, url, prevAcceptedVersion } =
          await extractTermsConditionsDataFromBlob(
            response as AxiosResponse<Blob>,
          );

        setTermsAndConditions({
          currentVersion: version,
          fileName,
          prevAcceptedVersion,
          url,
        });
        setIsLoading(false);
      } catch (error: any) {
        console.error('Error fetching Terms and Conditions:', error);

        setIsError(true);
        toast({
          description:
            'Unable to fetch the terms and conditions link. Please try again later.',
          title: 'Download error',
          variant: 'error',
        });
      }
    };

    checkIfNotAcceptedLatestTCs();
  }, [setTermsAndConditions, getAccessTokenSilently]);

  return { acceptedLatestTerms, isError, isLoading, setAcceptedLatestTerms };
};

export default useFetchIfNotLatestTermsConditions;
