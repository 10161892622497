import { type TdHTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

const layoutClasses = 'label-1 align-middle';
const spacingClasses = 'px-4 py-3.5';
const colorClasses = 'text-primary';
const loadingStateClasses =
  'animate-pulse rounded-md bg-slate-100 dark:bg-slate-800';

export const TableCell = forwardRef<
  HTMLTableCellElement,
  TdHTMLAttributes<HTMLTableCellElement> & { isLoading?: boolean }
>(({ className, children, isLoading, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(layoutClasses, spacingClasses, colorClasses, className)}
    {...props}
  >
    <div
      className={cn(
        'flex gap-1.5 items-center',
        isLoading ? loadingStateClasses : '',
      )}
    >
      {children}
    </div>
  </td>
));

TableCell.displayName = 'TableCell';

export default TableCell;
