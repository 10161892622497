import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

import { textEllipsis } from '@constants/css';

interface DetailsKeyValuePairProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  value: string | number;
}

const containerLayoutClasses = 'flex w-full justify-between items-start';
const containerSpacingClasses = 'py-[5px] px-4';

const textLayoutClasses = 'label-1';
const labelColorClasses = 'text-secondary';
const valueColorClasses = 'text-primary';

const DetailsKeyValuePair = forwardRef<
  HTMLDivElement,
  DetailsKeyValuePairProps
>(({ className, label, value, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(containerLayoutClasses, containerSpacingClasses, className)}
    {...props}
  >
    <span className={cn(textLayoutClasses, labelColorClasses, textEllipsis)}>
      {label}
    </span>
    <span className={cn(textLayoutClasses, valueColorClasses, textEllipsis)}>
      {value}
    </span>
  </div>
));

DetailsKeyValuePair.displayName = 'DetailsKeyValuePair';

export { DetailsKeyValuePair, type DetailsKeyValuePairProps };
