import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';
import { Viewport } from '@radix-ui/react-toast';

export const ToastViewport = forwardRef<
  ElementRef<typeof Viewport>,
  ComponentPropsWithoutRef<typeof Viewport>
>(({ className, ...props }, ref) => (
  <Viewport
    ref={ref}
    className={cn(
      'fixed top-0 z-[100] flex gap-2 max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px] focus-visible:outline-none',
      className,
    )}
    {...props}
  />
));
ToastViewport.displayName = Viewport.displayName;

export default ToastViewport;
