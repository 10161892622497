import { cn } from '@pxui/lib/utils';

import { createDetailsSubComponent } from './createDetailsSubComponent';

const layoutClasses = 'flex items-start w-full';
const spacingClasses = 'p-2 gap-2 mt-auto';

export const DetailsFooter = createDetailsSubComponent(
  'DetailsFooter',
  cn(layoutClasses, spacingClasses),
);

export default DetailsFooter;
