import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const CloseSmall: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.89742 13.8637L6.13623 13.1025L9.21798 9.99992L6.13623 6.91817L6.89742 6.15698L10 9.23873L13.0818 6.15698L13.8429 6.91817L10.7612 9.99992L13.8429 13.1025L13.0818 13.8637L10 10.7819L6.89742 13.8637Z"
      fill="currentColor"
    />
  </svg>
);

export default CloseSmall;
