import apiClient from '@services/interceptors/authInterceptor';

const fetchLatestTermsConditions = async () => {
  const response = await apiClient.get(
    `${process.env.PLATFORM_APP_MANAGEMENT_API}/v0/users/termsConditions?version=latest`,
    {
      responseType: 'blob',
    },
  );

  return response;
};

export default fetchLatestTermsConditions;
