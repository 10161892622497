import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Inbox: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.7565 16.5834C4.39111 16.5834 4.07647 16.4514 3.81258 16.1875C3.54869 15.9237 3.41675 15.609 3.41675 15.2436V4.7565C3.41675 4.38139 3.54869 4.06432 3.81258 3.80529C4.07647 3.54626 4.39111 3.41675 4.7565 3.41675H15.2436C15.6187 3.41675 15.9358 3.54626 16.1948 3.80529C16.4539 4.06432 16.5834 4.38139 16.5834 4.7565V15.2436C16.5834 15.609 16.4539 15.9237 16.1948 16.1875C15.9358 16.4514 15.6187 16.5834 15.2436 16.5834H4.7565ZM4.7565 15.5001H15.2436C15.3184 15.5001 15.3799 15.476 15.4279 15.4279C15.476 15.3799 15.5001 15.3184 15.5001 15.2436V12.3398H12.9263C12.6603 12.937 12.2647 13.4197 11.7396 13.7877C11.2145 14.1558 10.6347 14.3398 10.0001 14.3398C9.35156 14.3398 8.76476 14.1592 8.23964 13.7981C7.71455 13.437 7.32593 12.9509 7.07379 12.3398H4.50006V15.2436C4.50006 15.3184 4.5241 15.3799 4.57219 15.4279C4.62027 15.476 4.68171 15.5001 4.7565 15.5001ZM10.0001 13.2565C10.5556 13.2565 11.0278 13.0621 11.4167 12.6732C11.8056 12.2843 12.0001 11.8121 12.0001 11.2565H15.5001V4.7565C15.5001 4.68171 15.476 4.62027 15.4279 4.57219C15.3799 4.5241 15.3184 4.50006 15.2436 4.50006H4.7565C4.68171 4.50006 4.62027 4.5241 4.57219 4.57219C4.5241 4.62027 4.50006 4.68171 4.50006 4.7565V11.2565H8.00006C8.00006 11.8121 8.19451 12.2843 8.58339 12.6732C8.97228 13.0621 9.4445 13.2565 10.0001 13.2565ZM4.7565 15.5001H4.50006H15.5001H4.7565Z"
      fill="currentColor"
    />
  </svg>
);

export default Inbox;
