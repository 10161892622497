import { MutableRefObject, MouseEvent, useCallback, useState } from 'react';

import { ToggleSwitch } from '@pxui/components/ui/toggleSwitch';

import { useClipFilterProps } from '../../hooks/useClipFilterProps';
import { ClipPropertiesPanel } from '../ClipPlaneProperties/ClipPlaneProperties';
import { GeometryViewer } from '../GeometryViewer/GeometryViewerFactory';

export enum FilterTypes {
  CLIP = 'clip',
}
export interface Filter {
  type: FilterTypes;
}

interface FiltersProps {
  isEnabled: boolean;
  viewer?: MutableRefObject<GeometryViewer | undefined>;
}
export function Filters({ viewer, isEnabled }: FiltersProps) {
  const clipFilterProps = useClipFilterProps(viewer);
  const [filters, setFilters] = useState<Filter[]>([]);

  const addClipFilter = useCallback(() => {
    clipFilterProps.onActivateFilter();
    setFilters((curFilters) => [...curFilters, { type: FilterTypes.CLIP }]);
    // Call viewer.enableClipFilter();
  }, [clipFilterProps]);

  const removeClipFilter = useCallback(() => {
    clipFilterProps.onDeactivateFilter();
    setFilters([]);
    // viewer.disableClipFilter();
  }, [clipFilterProps]);

  const handleCrossSectionToggle = (e: MouseEvent<HTMLButtonElement>) => {
    const isChecked =
      (e.target as HTMLButtonElement).dataset.state === 'checked';
    if (isChecked) {
      removeClipFilter();
    } else {
      addClipFilter();
    }
  };

  const renderFilter = (filter: Filter, id: number) => {
    switch (filter.type) {
      case FilterTypes.CLIP:
        return <ClipPropertiesPanel key={id} {...clipFilterProps} />;
      default:
        return null;
    }
  };

  const crossSectionEnabled = !!filters.find(
    (f) => f.type === FilterTypes.CLIP,
  );

  return (
    <div>
      <div className="mb-5">
        <label htmlFor="cross-section" className="flex justify-between">
          <span className="label-1-strong">Cross-section</span>
          {isEnabled && (
            <ToggleSwitch
              id="cross-section"
              checked={crossSectionEnabled}
              onClick={handleCrossSectionToggle}
            />
          )}
        </label>
      </div>

      <div className="flex flex-col">
        {filters.map((filter, idx) => renderFilter(filter, idx))}
      </div>
    </div>
  );
}

export default Filters;
