import type { FC, ReactNode } from 'react';

import cn from '@pxui/lib/utils';

interface DetailsCardWrapperProps {
  children?: ReactNode;
  className?: string;
  id?: string;
  open?: boolean;
}

const layoutClasses = 'w-full max-w-0 max-h-[calc(100vh-48px)]';
const transitionClasses =
  'transition-[max-width,padding,opacity] duration-300 opacity-0';
const spacingClasses = 'py-4';

const stateOpenClasses = 'shrink-0 px-4 max-w-[312px] opacity-100';

const DetailsCardWrapper: FC<DetailsCardWrapperProps> = ({
  children,
  className,
  open,
  id,
}) => (
  <div
    id={id}
    className={cn(
      layoutClasses,
      transitionClasses,
      spacingClasses,
      { [stateOpenClasses]: open },
      className,
    )}
  >
    {children}
  </div>
);

export default DetailsCardWrapper;
