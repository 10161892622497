import React from 'react';

import { cn } from '@pxui/lib/utils';

interface CommandFooterShortcutProps
  extends React.HTMLAttributes<HTMLDivElement> {
  icons: React.ReactElement;
}

export const CommandFooterShortcut = ({
  className,
  children,
  icons,
  ...props
}: CommandFooterShortcutProps) => {
  return (
    <div
      className={cn('flex gap-2 justify-between items-center', className)}
      {...props}
    >
      <div className="flex border border-01-subtle p-1.5 rounded">{icons}</div>
      {children}
    </div>
  );
};

CommandFooterShortcut.displayName = 'CommandFooterShortcut';

export default CommandFooterShortcut;
