import type { FC, HTMLAttributes } from 'react';

import { DiffPrefix } from '@pxui/components/ui/diff';
import cn from '@pxui/lib/utils';

type DiffVariant = 'primary' | 'slot';

interface DiffProps extends HTMLAttributes<HTMLDivElement> {
  percentage?: boolean;
  value: number;
  variant?: DiffVariant;
}

const Diff: FC<DiffProps> = ({
  variant = 'primary',
  value,
  percentage,
  className,
}) => (
  <div
    className={cn(
      'inline-flex items-center',
      {
        'text-auxiliary-error': value < 0,
        'text-auxiliary-success': value > 0,
        'text-secondary': value === 0,
      },
      {
        'code-1': variant === 'slot',
        'paragraph-1': variant === 'primary',
      },
      className,
    )}
  >
    <DiffPrefix value={value} withIcon={variant === 'primary'} />
    <span>
      {Math.abs(value)}
      {percentage && '%'}
    </span>
  </div>
);

Diff.displayName = 'Diff';

export { Diff, type DiffProps };
