import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Info: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g filter="url(#filter0_i_833_8763)">
      <g clipPath="url(#clip0_833_8763)">
        <path
          d="M9.16675 14.1667H10.8334V9.16675H9.16675V14.1667ZM10.0001 7.50008C10.2362 7.50008 10.4342 7.42008 10.5942 7.26008C10.7542 7.10008 10.834 6.9023 10.8334 6.66675C10.8329 6.43119 10.7529 6.23342 10.5934 6.07341C10.434 5.91341 10.2362 5.83342 10.0001 5.83342C9.76397 5.83342 9.56619 5.91341 9.40675 6.07341C9.2473 6.23342 9.1673 6.43119 9.16675 6.66675C9.16619 6.9023 9.24619 7.10036 9.40675 7.26092C9.5673 7.42147 9.76508 7.50119 10.0001 7.50008ZM10.0001 18.3334C8.8473 18.3334 7.76397 18.1145 6.75008 17.6767C5.73619 17.239 4.85425 16.6454 4.10425 15.8959C3.35425 15.1465 2.76064 14.2645 2.32342 13.2501C1.88619 12.2356 1.6673 11.1523 1.66675 10.0001C1.66619 8.84786 1.88508 7.76453 2.32342 6.75008C2.76175 5.73564 3.35536 4.85369 4.10425 4.10425C4.85314 3.3548 5.73508 2.76119 6.75008 2.32341C7.76508 1.88564 8.84842 1.66675 10.0001 1.66675C11.1517 1.66675 12.2351 1.88564 13.2501 2.32341C14.2651 2.76119 15.147 3.3548 15.8959 4.10425C16.6448 4.85369 17.2387 5.73564 17.6776 6.75008C18.1165 7.76453 18.3351 8.84786 18.3334 10.0001C18.3317 11.1523 18.1129 12.2356 17.6767 13.2501C17.2406 14.2645 16.647 15.1465 15.8959 15.8959C15.1448 16.6454 14.2629 17.2392 13.2501 17.6776C12.2373 18.1159 11.154 18.3345 10.0001 18.3334ZM10.0001 16.6667C11.8612 16.6667 13.4376 16.0209 14.7292 14.7292C16.0209 13.4376 16.6667 11.8612 16.6667 10.0001C16.6667 8.13897 16.0209 6.56258 14.7292 5.27091C13.4376 3.97925 11.8612 3.33341 10.0001 3.33341C8.13897 3.33341 6.56258 3.97925 5.27092 5.27091C3.97925 6.56258 3.33342 8.13897 3.33342 10.0001C3.33342 11.8612 3.97925 13.4376 5.27092 14.7292C6.56258 16.0209 8.13897 16.6667 10.0001 16.6667Z"
          fill="#AAABB1"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_i_833_8763"
        x="0"
        y="0"
        width="20"
        height="20"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1"
          operator="erode"
          in="SourceAlpha"
          result="effect1_innerShadow_833_8763"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_833_8763"
        />
      </filter>
      <clipPath id="clip0_833_8763">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Info;
