import { FC } from 'react';

import cn from '@pxui/lib/utils';

import useProgressiveImg from '@hooks/useProgressiveImg';

interface ProgressiveImageProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  placeholderSrc?: string;
}

const ProgressiveImage: FC<ProgressiveImageProps> = ({
  src,
  placeholderSrc,
  alt,
  className,
  ...props
}) => {
  const [imageSrc] = useProgressiveImg(src || '', placeholderSrc);

  return <img src={imageSrc} className={cn(className)} alt={alt} {...props} />;
};

export default ProgressiveImage;
