import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const SwapVertical: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.08662 10.7115V4.49837L4.68277 6.90223L3.91675 6.12821L7.62827 2.41669L11.3398 6.12821L10.5738 6.90223L8.16994 4.49837V10.7115H7.08662ZM12.3638 17.5833L8.65231 13.8718L9.41831 13.0978L11.8222 15.5016V9.28848H12.9055V15.5016L15.3094 13.0978L16.0754 13.8718L12.3638 17.5833Z"
      fill="#F4F4F4"
    />
  </svg>
);

export default SwapVertical;
