import {
  createContext,
  useContext,
  useEffect,
  useState,
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { setupInterceptors } from '@services/interceptors/authInterceptor';

interface Auth0TokenContextProps {
  accessToken: string | undefined;
}

/**
 * Auth0TokenContext is used to share the Auth0 token and related error state across the application.
 */
const Auth0TokenContext = createContext<Auth0TokenContextProps | undefined>(
  undefined,
);

/**
 * Auth0TokenProvider component fetches and provides the Auth0 token and error state to its children.
 *
 * @param {object} props - The properties object.
 * @param {ReactNode} props.children - The child components that need access to the Auth0 token context.
 *
 * @returns {JSX.Element} The Auth0TokenProvider component.
 */
const Auth0TokenProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  // Ref to track if the popup has been called
  const popupPromiseRef = useRef<Promise<void> | null>(null);

  /**
   * Fetch the access token, trying silently first, then with a popup if necessary.
   *
   * @async
   * @returns {Promise<string | undefined>} The fetched access token.
   */
  const fetchAccessToken = useCallback(async () => {
    let token;

    try {
      // Try to get the token silently
      token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (silentFetchError: any) {
      if (!popupPromiseRef.current) {
        popupPromiseRef.current = (async () => {
          try {
            // If silent fetch fails, try with a popup
            token = await getAccessTokenWithPopup();
            setAccessToken(token);
          } catch (popupFetchError: any) {
            // If both methods fail, log the error in development mode and redirect to login
            if (process.env.NODE_ENV === 'development') {
              // eslint-disable-next-line no-console
              console.error(
                `Error fetching access token: ${silentFetchError?.message || silentFetchError.toString()} ${popupFetchError?.message || popupFetchError.toString()}`,
              );
            }
            loginWithRedirect();
          } finally {
            // Reset popupPromiseRef to allow future attempts if needed
            popupPromiseRef.current = null;
          }
        })();
      }

      // Wait for the popup promise to resolve
      await popupPromiseRef.current;
    }

    return token;
  }, [getAccessTokenSilently, getAccessTokenWithPopup, loginWithRedirect]);

  /**
   * Setup interceptors if the user is authenticated.
   * This ensures interceptors are ready as soon as possible if the user is already authenticated.
   */
  if (isAuthenticated) {
    setupInterceptors(accessToken, fetchAccessToken, loginWithRedirect);
  }
  /**
   * Use effect to fetch token and set up interceptors when authentication status or token changes.
   * Ensures interceptors are always in sync with the latest authentication state and access token.
   */
  useEffect(() => {
    if (isAuthenticated) {
      fetchAccessToken();
      setupInterceptors(accessToken, fetchAccessToken, loginWithRedirect);
    }
  }, [accessToken, fetchAccessToken, isAuthenticated, loginWithRedirect]);
  /**
   * The duplicate code and additional call to setupInterceptors outside of the useEffect
   * are necessary to ensure that interceptors are set up immediately when the component mounts
   * if the user is already authenticated.
   */

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({ accessToken, fetchAccessToken }),
    [accessToken, fetchAccessToken],
  );

  return (
    <Auth0TokenContext.Provider value={contextValue}>
      {children}
    </Auth0TokenContext.Provider>
  );
};

/**
 * useAuth0TokenContext hook provides access to the Auth0 token context.
 *
 * @returns {Auth0TokenContextProps} The current context value.
 *
 * @throws Will throw an error if used outside of Auth0TokenProvider.
 */
const useAuth0TokenContext = (): Auth0TokenContextProps => {
  const context = useContext(Auth0TokenContext);
  if (!context) {
    throw new Error(
      'useAuth0TokenContext must be used within an Auth0TokenProvider',
    );
  }
  return context;
};

export { Auth0TokenProvider, useAuth0TokenContext };
