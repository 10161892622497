import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

export const TableCaption = forwardRef<
  HTMLTableCaptionElement,
  HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn('mt-4 text-sm text-slate-500 dark:text-slate-400', className)}
    {...props}
  />
));

TableCaption.displayName = 'TableCaption';

export default TableCaption;
