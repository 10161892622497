import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';
import { Title } from '@radix-ui/react-toast';

export const ToastTitle = forwardRef<
  ElementRef<typeof Title>,
  ComponentPropsWithoutRef<typeof Title>
>(({ className, ...props }, ref) => (
  <Title
    ref={ref}
    className={cn('text-sm font-semibold', className)}
    {...props}
  />
));

ToastTitle.displayName = Title.displayName;

export default ToastTitle;
