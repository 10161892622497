import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Analytics: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.30432 13.7916H7.38763V10H6.30432V13.7916ZM12.612 13.7916H13.6953V6H12.612V13.7916ZM9.45817 13.7916H10.5415V11H9.45817V13.7916ZM9.45817 10H10.5415V8.5H9.45817V10ZM4.75625 16.5833C4.38114 16.5833 4.06407 16.4538 3.80505 16.1948C3.54602 15.9357 3.4165 15.6187 3.4165 15.2436V4.75644C3.4165 4.38133 3.54602 4.06426 3.80505 3.80523C4.06407 3.5462 4.38114 3.41669 4.75625 3.41669H15.2434C15.6185 3.41669 15.9356 3.5462 16.1946 3.80523C16.4536 4.06426 16.5831 4.38133 16.5831 4.75644V15.2436C16.5831 15.6187 16.4536 15.9357 16.1946 16.1948C15.9356 16.4538 15.6185 16.5833 15.2434 16.5833H4.75625ZM4.75625 15.5H15.2434C15.3075 15.5 15.3663 15.4733 15.4197 15.4199C15.4731 15.3664 15.4998 15.3077 15.4998 15.2436V4.75644C15.4998 4.69233 15.4731 4.63356 15.4197 4.58013C15.3663 4.52671 15.3075 4.5 15.2434 4.5H4.75625C4.69214 4.5 4.63337 4.52671 4.57994 4.58013C4.52652 4.63356 4.49982 4.69233 4.49982 4.75644V15.2436C4.49982 15.3077 4.52652 15.3664 4.57994 15.4199C4.63337 15.4733 4.69214 15.5 4.75625 15.5Z"
      fill="currentColor"
    />
  </svg>
);

export default Analytics;
