import { type FC } from 'react';

import { ArrowLeftIcon, ArrowRightIcon, Button } from '@pxui/components/ui';

interface GeometryData {
  filename: string;
  url: string;
}

interface GeometryInspectorControlsProps {
  geometries: GeometryData[];
  geometry: GeometryData;
  setGeometry: React.Dispatch<React.SetStateAction<GeometryData | null>>;
}

const GeometryInspectorControls: FC<GeometryInspectorControlsProps> = ({
  geometry,
  geometries,
  setGeometry,
}) => {
  const currentIndex = geometries?.indexOf(geometry);

  const onPrevGeometryHandler = () => {
    if (currentIndex > 0) {
      setGeometry(geometries[currentIndex - 1]);
    }
  };

  const onNextGeometryHandler = () => {
    if (currentIndex < geometries.length - 1) {
      setGeometry(geometries[currentIndex + 1]);
    }
  };

  return (
    <div className="absolute bottom-4 right-4 flex justify-center items-center gap-2">
      <div className="w-8 h-8 p-1 bg-surface-container-high rounded">
        <Button
          className="w-6 h-6 p-0 bg-surface-container-high rounded-sm"
          disabled={currentIndex === 0}
          icon={ArrowLeftIcon}
          layout="iconOnly"
          onClick={onPrevGeometryHandler}
          variant="ghost"
        />
      </div>
      <div className="w-8 h-8 p-1 bg-surface-container-high rounded">
        <Button
          className="w-6 h-6 p-0 bg-surface-container-high rounded-sm"
          disabled={currentIndex === geometries.length - 1}
          icon={ArrowRightIcon}
          layout="iconOnly"
          onClick={onNextGeometryHandler}
          variant="ghost"
        />
      </div>
    </div>
  );
};

export default GeometryInspectorControls;
