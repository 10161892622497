import type { FC, HTMLAttributes } from 'react';

import cn from '@pxui/lib/utils';
import { type VariantProps, cva } from 'class-variance-authority';

const indicatorLabelVariants = cva('label-2 ml-auto', {
  defaultVariants: {
    variant: 'active',
  },
  variants: {
    variant: {
      active: 'text-primary',
      error: 'text-auxiliary-error',
      success: 'text-auxiliary-success',
    },
  },
});

interface IndicatorLabelProps
  extends HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof indicatorLabelVariants> {
  label: string;
}

const IndicatorLabel: FC<IndicatorLabelProps> = ({ variant, label }) => (
  <span className={cn(indicatorLabelVariants({ variant }))}>{label}</span>
);

export { IndicatorLabel, indicatorLabelVariants, type IndicatorLabelProps };
