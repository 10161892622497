import { Progress } from '@pxui/components/ui/progress';
import ProgressVariant, {
  type DetermineProgressStatusFunction,
} from '@pxui/components/ui/progress/types';
import { formatElapsedTime } from '@vault/utils/formatElapsedTime';

export const ToastProgress = ({
  indicatorLabel,
  elapsed,
  error,
  progress,
}: {
  elapsed: number;
  error?: boolean;
  indicatorLabel: string;
  progress: number;
}) => {
  const determineProgressStatus: DetermineProgressStatusFunction = (
    progressValue,
    progressError,
  ) => {
    if (progressError) {
      return {
        indicatorLabel: 'Error',
        variant: ProgressVariant.Error,
      };
    }

    if (progressValue === 100) {
      return {
        indicatorLabel,
        variant: ProgressVariant.Success,
      };
    }

    return {
      indicatorLabel,
      variant: ProgressVariant.Active,
    };
  };

  return (
    <Progress
      className="w-full"
      determineProgressStatus={determineProgressStatus}
      elapsed={formatElapsedTime(elapsed)}
      value={progress}
      error={error}
    />
  );
};

export default ToastProgress;
