import { cn } from '@pxui/lib/utils';

import { createDetailsSubComponent } from './createDetailsSubComponent';

const layoutClasses =
  'flex flex-col items-start w-full overflow-y-auto overflow-x-hidden';
const spacingClasses = 'gap-6';

export const DetailsMain = createDetailsSubComponent(
  'DetailsMain',
  cn(layoutClasses, spacingClasses),
);

export default DetailsMain;
