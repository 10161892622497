import { cn } from '@pxui/lib/utils';

export const CommandShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn(
        'flex justify-center items-center size-6 border-01-inverse label-1 text-secondary gap-4 rounded border',
        className,
      )}
      {...props}
    />
  );
};

CommandShortcut.displayName = 'CommandShortcut';

export default CommandShortcut;
