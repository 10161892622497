import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

interface SectionHeaderLeftProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
}

const containerLayoutClasses = 'flex items-center';
const containerSpacingClasses = 'gap-8';

const headingLayoutClasses = 'title-2 flex-inline';
const headingColorClasses = 'text-primary';

const SectionHeaderLeft = forwardRef<HTMLDivElement, SectionHeaderLeftProps>(
  ({ title, children }, ref) => (
    <div
      className={cn(containerLayoutClasses, containerSpacingClasses)}
      ref={ref}
    >
      <h1 className={cn(headingLayoutClasses, headingColorClasses)}>{title}</h1>

      {children && <div>{children}</div>}
    </div>
  ),
);

SectionHeaderLeft.displayName = 'SectionHeaderLeft';

export { SectionHeaderLeft, type SectionHeaderLeftProps };
