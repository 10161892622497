import type { FC, HTMLAttributes } from 'react';

import { ProgressBar, IndicatorLabel } from '@pxui/components/ui/progress';
import { cn } from '@pxui/lib/utils';

import { ProgressVariant, type DetermineProgressStatusFunction } from './types';

interface ProgressProps extends HTMLAttributes<HTMLDivElement> {
  determineProgressStatus?: DetermineProgressStatusFunction;
  elapsed?: string;
  error?: boolean;
  value: number;
}

const handleValue = (value: number) => {
  if (value < 100) {
    return {
      indicatorLabel: `${value}%`,
      variant: ProgressVariant.Active,
    };
  }

  return {
    indicatorLabel: 'Success',
    variant: ProgressVariant.Success,
  };
};

const determineProgressStatusDefault: DetermineProgressStatusFunction = (
  value,
  error,
) => {
  if (error) {
    return {
      indicatorLabel: 'Error',
      variant: ProgressVariant.Error,
    };
  }

  return handleValue(value);
};

const Progress: FC<ProgressProps> = ({
  elapsed,
  value,
  className,
  determineProgressStatus = determineProgressStatusDefault,
  error,
  ...props
}) => {
  const { indicatorLabel, variant } = determineProgressStatus(value, error);

  return (
    <div className={cn('flex flex-col gap-1', className)} {...props}>
      <ProgressBar variant={variant} value={value} />

      <div className="h-4 w-full flex justify-between items-start">
        {elapsed && <span className="label-2 text-primary">{elapsed}</span>}
        <IndicatorLabel label={indicatorLabel} variant={variant} />
      </div>
    </div>
  );
};

Progress.displayName = 'Progress';

export { Progress, type ProgressProps };
