import type { FC, FunctionComponent, ReactNode } from 'react';

import { cn } from '@pxui/lib/utils';

import { textEllipsis } from '@constants/css';

import { TreeViewSpacer } from './treeView';

import type { IconProps } from '@pxui/components/ui/icons';

interface ViewItemContentProps {
  caret?: ReactNode;
  icon?: FunctionComponent<IconProps>;
  label: string;
  nestLevel?: number;
  selected?: boolean;
  textRight?: string;
}

const ViewItemContent: FC<ViewItemContentProps> = ({
  icon: Icon,
  selected,
  caret,
  textRight,
  label,
  nestLevel,
}) => (
  <div
    className={cn(
      'rounded pr-2 flex items-center group h-full cursor-pointer w-full view-content',
      {
        'hover:state-hover': !selected,
        'pl-2': !caret,
        'state-selected': selected,
      },
    )}
  >
    <TreeViewSpacer level={nestLevel} />
    {caret || null}

    <div className="h-5 flex justify-between items-end w-full">
      <div
        className={cn(
          'grid grid-cols-[auto_1fr_auto] text-on-surface-subtle group-hover:text-on-surface grid-rows-1 items-end max-w-52',
          {
            'text-on-surface': selected,
          },
        )}
      >
        {Icon && <Icon className="mr-2" />}
        <span className={cn('label-1', textEllipsis)}>{label}</span>
      </div>

      <div className="grid grid-cols-[auto_1fr_auto] items-end">
        <div className="h-full w-4" />
        {!!textRight && (
          <span className="label-1 text-on-surface-subtle">{textRight}</span>
        )}
      </div>
    </div>
  </div>
);

export { ViewItemContent, type ViewItemContentProps };
