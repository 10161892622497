export const Ellipse = ({ color, size }: { color: string; size: number }) => {
  return (
    <svg
      className="absolute"
      width={size}
      height={size}
      viewBox="0 0 336 336"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="168" cy="168" r="167.5" stroke={color} />
    </svg>
  );
};

export default Ellipse;
