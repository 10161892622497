import type { FC } from 'react';

import { cn } from '@pxui/lib/utils';

interface DetailsErrorProps {
  text?: string;
}

const layoutClasses = 'label-2';
const spacingClasses = 'px-4 py-1';
const colorClasses = 'text-error';

const DetailsError: FC<DetailsErrorProps> = ({ text }) => {
  if (!text) return null;

  return (
    <span className={cn(layoutClasses, spacingClasses, colorClasses)}>
      {text}
    </span>
  );
};

export { DetailsError, type DetailsErrorProps };
