import { type InputHTMLAttributes, FC, useRef, type ChangeEvent } from 'react';

import { linkVariants } from '@pxui/components/ui/link';
import { cn } from '@pxui/lib/utils';

import { textEllipsis } from '@constants/css';

interface FileInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  errorText?: string;
  helperText?: string;
  id: string;
  labelText?: string;
  onChange?: (file: File | null) => void;
  value?: File | null;
  wrapperClassName?: string;
}

const FileInput: FC<FileInputProps> = ({
  className,
  id,
  value,
  onChange,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    inputRef.current?.click();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.currentTarget.files?.[0] || null);
  };

  return (
    <>
      <input
        hidden
        type="file"
        name="file-input"
        id={id}
        ref={inputRef}
        onChange={handleInputChange}
        value=""
        {...props}
      />
      <button
        dir="rtl"
        title={value?.name}
        type="button"
        className={cn(
          value
            ? 'label-1 text-primary border-b border-transparent text-right'
            : linkVariants({ className, variant: 'primary' }),
          'max-w-[50%]',
          textEllipsis,
        )}
        aria-label="Upload file"
        onClick={handleButtonClick}
      >
        {value?.name || 'Select'}
      </button>
    </>
  );
};

FileInput.displayName = 'FileInput';

export { FileInput, type FileInputProps };
