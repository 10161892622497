import React from 'react';

import { cn } from '@pxui/lib/utils';
import { Command as CommandPrimitive } from 'cmdk';

export const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & {
    testId?: string;
  }
>(({ className, testId, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      'select-none rounded-lg title-3 text-primary hover:not(aria-selected):surface-01-enabled aria-selected:surface-01-enabled',
      className,
    )}
    data-testid={testId}
    {...props}
  />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

export default CommandItem;
