import { DetailsCard } from './detailsCard';
import { DetailsDivider } from './detailsDivider';
import { DetailsError, type DetailsErrorProps } from './detailsError';
import {
  DetailsFileInput,
  type DetailsFileInputProps,
} from './detailsFileInput';
import { DetailsFooter } from './detailsFooter';
import { DetailsHeader } from './detailsHeader';
import {
  DetailsKeyValuePair,
  type DetailsKeyValuePairProps,
} from './detailsKeyValuePair';
import { DetailsLink, type DetailsLinkProps } from './detailsLink';
import { DetailsList, type DetailsListProps } from './detailsList';
import { DetailsListItem } from './detailsListItem';
import { DetailsLoader } from './detailsLoader';
import { DetailsMain } from './detailsMain';
import { DetailsPreview } from './detailsPreview';
import { DetailsSection } from './detailsSection';
import {
  DetailsSectionTitle,
  type DetailsSectionTitleProps,
} from './detailsSectionTitle';
import { DetailsString } from './detailsString';
import { DetailsTitle } from './detailsTitle';

export {
  DetailsCard,
  DetailsError,
  DetailsPreview,
  DetailsTitle,
  DetailsMain,
  DetailsFooter,
  DetailsHeader,
  DetailsSection,
  DetailsSectionTitle,
  DetailsKeyValuePair,
  DetailsLink,
  DetailsString,
  DetailsList,
  DetailsListItem,
  DetailsLoader,
  DetailsDivider,
  DetailsFileInput,
  type DetailsKeyValuePairProps,
  type DetailsLinkProps,
  type DetailsListProps,
  type DetailsSectionTitleProps,
  type DetailsFileInputProps,
  type DetailsErrorProps,
};
