import { type HTMLAttributes, forwardRef } from 'react';

import cn from '@pxui/lib/utils';

export const TileTitle = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn('p-3 text-secondary title-3', className)}
    {...props}
  >
    {children}
  </h3>
));

TileTitle.displayName = 'TileTitle';

export default TileTitle;
