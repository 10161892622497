import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const ChevronRight: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.1631 10L7.22559 6.06253L7.99954 5.28857L12.711 10L7.99954 14.7115L7.22559 13.9375L11.1631 10Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronRight;
