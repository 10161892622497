import { FC } from 'react';

//   from https://github.com/Kitware/VTK/blob/master/Common/Color/vtkColorSeries.cxx
export const COLOR_SERIES: Record<string, string[]> = {
  blues: [
    '#3b68ab',
    '#1c95cd',
    '#4ed9ea',
    '#739ad5',
    '#423da9',
    '#505487',
    '#102a52',
  ],
  citrus: ['#657c37', '#75b101', '#b2ba30', '#ffe506', '#ffb400', '#f98324'],
  cool: [
    '#75b101',
    '#588029',
    '#50d7bf',
    '#1c95cd',
    '#3b68ab',
    '#9a68ff',
    '#5f3380',
  ],
  spectrum: [
    '#000000',
    '#e41a1c',
    '#377eb8',
    '#4daf4a',
    '#984ea3',
    '#ff7f00',
    '#a65628',
  ],
  warm: ['#791717', '#B50101', '#EF4719', '#F98324', '#FFB400', '#FFE506'],
  wildflower: [
    '#1c95cd',
    '#3b68ab',
    '#663eb7',
    '#a254cf',
    '#de61ce',
    '#dc6195',
    '#3d1052',
  ],
};

interface MeshColorLegendProps {
  colorPreset: string;
  range: [number, number];
}

const legendFormatter = (number: number) => {
  const absNumber = Math.abs(number);
  let decimalPlaces;

  if (absNumber >= 100) {
    decimalPlaces = 0; // No decimal places for numbers >= 100
  } else if (absNumber >= 1 || absNumber === 0) {
    decimalPlaces = 2; // 2 decimal places for numbers between 1 and 100
  } else {
    // Calculate the number of significant figures for numbers < 1
    decimalPlaces = Math.ceil(-Math.log10(absNumber)) + 1;
  }

  return number.toFixed(decimalPlaces);
};

const MeshColorLegend: FC<MeshColorLegendProps> = ({
  range,
  colorPreset = '',
}) => {
  const key = colorPreset.toLowerCase();
  const colors = key in COLOR_SERIES ? COLOR_SERIES[key] : [];

  const style = {
    background: `linear-gradient(to bottom, ${colors.join(',')})`,
  };

  const topRange = legendFormatter(range[1]);
  const bottomRange = legendFormatter(range[0]);

  return (
    <div className="text-primary w-5 h-full" style={style}>
      <div className="absolute left-1/2 transform -translate-x-1/2 -top-6">
        <span className="label-1 px-2">{topRange}</span>
      </div>
      <span className="w-5 h-full block" style={style} />
      <div className="absolute left-1/2 transform -translate-x-1/2 -bottom-6">
        <span className="label-1 px-2">{bottomRange}</span>
      </div>
    </div>
  );
};

export default MeshColorLegend;
