import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Folder: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.7565 15.5833C3.39111 15.5833 3.07647 15.4514 2.81258 15.1875C2.54869 14.9236 2.41675 14.609 2.41675 14.2436V5.75644C2.41675 5.38133 2.54869 5.06426 2.81258 4.80523C3.07647 4.5462 3.39111 4.41669 3.7565 4.41669H7.83177L9.83177 6.41669H16.2436C16.6187 6.41669 16.9358 6.5462 17.1948 6.80523C17.4539 7.06426 17.5834 7.38133 17.5834 7.75644V14.2436C17.5834 14.609 17.4539 14.9236 17.1948 15.1875C16.9358 15.4514 16.6187 15.5833 16.2436 15.5833H3.7565ZM3.7565 14.5H16.2436C16.3184 14.5 16.3799 14.476 16.4279 14.4279C16.476 14.3798 16.5001 14.3184 16.5001 14.2436V7.75644C16.5001 7.68165 16.476 7.62021 16.4279 7.57212C16.3799 7.52404 16.3184 7.5 16.2436 7.5H9.38308L7.38308 5.5H3.7565C3.68171 5.5 3.62027 5.52404 3.57219 5.57212C3.5241 5.62021 3.50006 5.68165 3.50006 5.75644V14.2436C3.50006 14.3184 3.5241 14.3798 3.57219 14.4279C3.62027 14.476 3.68171 14.5 3.7565 14.5Z"
      fill="currentColor"
    />
  </svg>
);

export default Folder;
