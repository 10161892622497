import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const ArrowDropDown: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      d="M9.99999 11.7115L6.49683 8.20837H13.5032L9.99999 11.7115Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowDropDown;
