import type { FC, ReactNode } from 'react';

interface RangeLabelProps {
  children: ReactNode;
  left?: number;
  right?: number;
}

const RangeLabel: FC<RangeLabelProps> = ({
  left,
  right,
  children,
  ...props
}) => {
  if (!left && !right) return children;

  return (
    <div
      className="flex flex-row justify-between items-center h-[32px] label-1 text-on-surface-subtle"
      {...props}
    >
      <span className="pr-[12px]">{left}</span>
      {children}
      <span className="pl-[12px]">{right}</span>
    </div>
  );
};

export { RangeLabel, type RangeLabelProps };
