import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Grade: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M7.3332 13.8959L9.99987 12.3126L12.6874 13.8959L11.979 10.8959L14.2915 8.91675L11.229 8.64592L9.99987 5.79175L8.7707 8.64592L5.7082 8.91675L8.04154 10.8959L7.3332 13.8959ZM5.68737 16.1347L6.83966 11.3061L3.01758 8.05779L8.03029 7.62196L9.99987 3.06592L11.9695 7.64279L16.9822 8.05779L13.1601 11.3061L14.3124 16.1347L9.99987 13.5738L5.68737 16.1347Z"
      fill="currentColor"
    />
  </svg>
);

export default Grade;
