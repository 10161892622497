import lazyLoadHelper from '@utils/lazyLoadHelper';

import flowImageSmall from '@assets/images/apps/flow_small.png';
import flowImage from '@assets/images/launcher/flow.png';

import { type AppConfig } from '@typing/AppConfig';

import { routes } from './routes';

export const config: AppConfig = {
  appName: 'Flow',
  description: 'Run ML processes on scalable compute',
  icon: flowImageSmall,
  id: 'flow',
  imageSrc: flowImage,
  navigationLinks: [
    {
      label: 'Home',
      path: '/app/flow',
    },
    {
      label: 'Pipelines',
      path: '/app/flow/pipelines',
    },
    {
      label: 'Runs',
      path: '/app/flow/runs',
    },
  ],
  path: 'app/flow',
  root: lazyLoadHelper(() => import('@flow/pages/index')),
  routes,
};

export default config;
