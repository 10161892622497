import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const BorderAll: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M3.41602 16.5834V3.41675H16.5827V16.5834H3.41602ZM15.4993 15.5001V10.5417H10.541V15.5001H15.4993ZM15.4993 4.50008H10.541V9.45842H15.4993V4.50008ZM4.49935 4.50008V9.45842H9.45768V4.50008H4.49935ZM4.49935 15.5001H9.45768V10.5417H4.49935V15.5001Z"
      fill="currentColor"
    />
  </svg>
);

export default BorderAll;
