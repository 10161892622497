import type { FC, HTMLAttributes } from 'react';

import cn from '@pxui/lib/utils';

const GeometryInspectorHeader: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <div
    className={cn('p-2 w-full flex gap-2 justify-between', className)}
    {...props}
  >
    {children}
  </div>
);

GeometryInspectorHeader.displayName = 'GeometryInspectorHeader';

export default GeometryInspectorHeader;
