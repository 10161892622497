import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Link: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.83963 13.6154H6.03192C5.03164 13.6154 4.179 13.2625 3.474 12.5567C2.769 11.8508 2.4165 10.9972 2.4165 9.99583C2.4165 8.9943 2.769 8.14208 3.474 7.43917C4.179 6.73611 5.03164 6.38458 6.03192 6.38458H8.83963V7.46792H6.03192C5.33206 7.46792 4.73512 7.715 4.24109 8.20916C3.74692 8.70333 3.49984 9.30028 3.49984 10C3.49984 10.6997 3.74692 11.2967 4.24109 11.7908C4.73512 12.285 5.33206 12.5321 6.03192 12.5321H8.83963V13.6154ZM7.20817 10.5417V9.45833H12.7915V10.5417H7.20817ZM11.16 13.6154V12.5321H13.9678C14.6676 12.5321 15.2646 12.285 15.7586 11.7908C16.2528 11.2967 16.4998 10.6997 16.4998 10C16.4998 9.30028 16.2528 8.70333 15.7586 8.20916C15.2646 7.715 14.6676 7.46792 13.9678 7.46792H11.16V6.38458H13.9678C14.968 6.38458 15.8207 6.7375 16.5257 7.44333C17.2307 8.14917 17.5832 9.00278 17.5832 10.0042C17.5832 11.0057 17.2307 11.8579 16.5257 12.5608C15.8207 13.2639 14.968 13.6154 13.9678 13.6154H11.16Z"
      fill="currentColor"
    />
  </svg>
);

export default Link;
