import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Add: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.45842 10.5416H5.41675V9.45835H9.45842V5.41669H10.5417V9.45835H14.5834V10.5416H10.5417V14.5833H9.45842V10.5416Z"
      fill="currentColor"
    />
  </svg>
);

export default Add;
