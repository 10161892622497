export interface Settings {
  animate?: boolean;
  backgroundColor1?: number;
  backgroundColor2?: number;
  colorArrays?: Array<string>;

  colorByArray?: string;

  colorMapPreset?: string;
  ditherGradient?: boolean;
  edgeColor?: number;

  edgeOpacity?: number;
  highlightOnHover?: number;
  interpolateScalarsBeforeMapping?: boolean;
  lineWidth?: number;
  mouseWheelMotionFactor?: number;
  opacity?: number;
  orthographic?: boolean;
  pointSize?: number;
  representation?: number;

  showControls?: boolean;
  solidColor?: number;
  url?: string;
  vertexColor?: number;
  vertexVisibility?: boolean;
  viewApi: string;
}

export const MeshRepresentationStringToValueMap = {
  Points: 0,
  Wireframe: 1,
  Surface: 2,
  SurfaceWithEdges: 3,
};

export type MeshRepresentationLabel =
  keyof typeof MeshRepresentationStringToValueMap;
