import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Filters: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <mask
      id="mask0_655_13256"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <rect width="20" height="20" fill="#FFFFFF" />
    </mask>
    <g mask="url(#mask0_655_13256)">
      <path
        d="M14.4198 16.5031C13.6741 16.5031 13.0438 16.2456 12.5288 15.7308C12.0139 15.2158 11.7565 14.5854 11.7565 13.8397C11.7565 13.0939 12.0139 12.4636 12.5288 11.9487C13.0438 11.4337 13.6741 11.1762 14.4198 11.1762C15.1656 11.1762 15.796 11.4337 16.3109 11.9487C16.8259 12.4636 17.0834 13.0939 17.0834 13.8397C17.0834 14.5854 16.8259 15.2158 16.3109 15.7308C15.796 16.2456 15.1656 16.5031 14.4198 16.5031ZM14.4217 15.4197C14.8585 15.4197 15.2308 15.2654 15.5386 14.9566C15.8462 14.6477 16 14.2748 16 13.8379C16 13.4011 15.8456 13.0288 15.5367 12.721C15.2279 12.4134 14.8551 12.2595 14.4181 12.2595C13.9812 12.2595 13.6089 12.414 13.3013 12.7229C12.9936 13.0316 12.8398 13.4045 12.8398 13.8414C12.8398 14.2784 12.9942 14.6506 13.3029 14.9583C13.6118 15.2659 13.9847 15.4197 14.4217 15.4197ZM3.91669 14.3814V13.2981H10.0963V14.3814H3.91669ZM5.58023 8.8237C4.8344 8.8237 4.20405 8.5662 3.68919 8.0512C3.17419 7.53634 2.91669 6.90599 2.91669 6.16016C2.91669 5.41447 3.17419 4.78412 3.68919 4.26912C4.20405 3.75426 4.8344 3.49683 5.58023 3.49683C6.32592 3.49683 6.95627 3.75426 7.47127 4.26912C7.98613 4.78412 8.24356 5.41447 8.24356 6.16016C8.24356 6.90599 7.98613 7.53634 7.47127 8.0512C6.95627 8.5662 6.32592 8.8237 5.58023 8.8237ZM5.5819 7.74037C6.01884 7.74037 6.39113 7.58592 6.69877 7.27703C7.00641 6.96828 7.16023 6.59544 7.16023 6.15849C7.16023 5.72155 7.00585 5.34926 6.6971 5.04162C6.38821 4.73398 6.0153 4.58016 5.57835 4.58016C5.14155 4.58016 4.76926 4.73454 4.46148 5.04329C4.15384 5.35217 4.00002 5.72509 4.00002 6.16204C4.00002 6.59884 4.15446 6.97113 4.46335 7.27891C4.7721 7.58655 5.14495 7.74037 5.5819 7.74037ZM9.90377 6.70183V5.61849H16.0834V6.70183H9.90377Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Filters;
