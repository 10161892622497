import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

export function createDetailsSubComponent(
  displayName: string,
  defaultClassName: string,
) {
  const Component = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, children, ...props }, ref) => (
      <div ref={ref} className={cn(defaultClassName, className)} {...props}>
        {children}
      </div>
    ),
  );

  Component.displayName = displayName;

  return Component;
}

export default createDetailsSubComponent;
