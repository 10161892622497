import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
} from 'react';

import { ArrowRightIcon } from '@pxui/components/ui/icons';
import { cn } from '@pxui/lib/utils';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

export const DropdownMenuSubTrigger = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    inset?: boolean;
  }
>(({ className, inset, children, ...props }, ref) => (
  <DropdownMenuPrimitive.SubTrigger
    ref={ref}
    className={cn(
      'flex justify-between h-8 cursor-pointer select-none items-center label-1 text-secondary outline-none hover:state-hover focus:state-hover data-[state=open]:state-hover',
      inset && 'pl-8',
      className,
    )}
    {...props}
  >
    {children}
    <span className="h-8 w-8 flex items-center justify-center">
      <ArrowRightIcon className="text-icon-primary fill-icon-primary" />
    </span>
  </DropdownMenuPrimitive.SubTrigger>
));

DropdownMenuSubTrigger.displayName =
  DropdownMenuPrimitive.SubTrigger.displayName;

export default DropdownMenuSubTrigger;
