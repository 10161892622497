import lazyLoadHelper from '@utils/lazyLoadHelper';

import vaultImageSmall from '@assets/images/apps/vault_small.png';
import vaultImage from '@assets/images/launcher/vault.png';
import { VAULT_URL } from '@constants/common';

import { type AppConfig } from '@typing/AppConfig';

import { routes } from './routes';

export const config: AppConfig = [
  // We are currently rolling out new Vault to individual namespaces.
  // This conditional defines which namespaces get old Vault and which
  // get new Vault (which have different domains) on their homepage.
  // This is temporary and eventually all namespaces will use the same Vault.
  // Note: platform-dev requires old Vault for cypress tests
  //
  // Product Namespaces
  'platform-sandbox',
  'internal',
  //
  // Delivery Namespaces
  'ampere',
  'bramley',
  'donatello',
  'interstellar',
  'isaac',
].includes(process.env.PLATFORM_NAMESPACE || '')
  ? {
      appName: 'Vault',
      description: 'Securely upload and share data',
      external: true,
      id: 'vault',
      imageSrc: vaultImage,
      navigationLinks: [], // external app don't have any child routes
      path: VAULT_URL,
      routes: [], // external app don't have any child routes
    }
  : {
      appName: 'Vault',
      description: 'Securely upload and share data',
      icon: vaultImageSmall,
      id: 'vault',
      imageSrc: vaultImage,
      navigationLinks: [],
      path: 'app/vault',
      root: lazyLoadHelper(() => import('@vault/pages/index')),
      routes,
    };

export default config;
