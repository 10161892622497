import type { FieldData } from '../components/GeometryViewer/GeometryViewerFactory';

export function parseSingleFieldData(fieldData: FieldData[]) {
  return fieldData.map((field) => {
    // we want to display actual values only for fields with items with one tuple
    const value =
      field.numTuples === 1
        ? field.data.join(',')
        : `<num_tuples:${field.numTuples}>`;
    return [field.name, value];
  });
}

function parseFieldData(fieldData: FieldData[][]) {
  return fieldData.filter((f) => f).map(parseSingleFieldData);
}

export default parseFieldData;
