import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Dot: FC<IconProps> = ({
  className,
  color = '#879A00',
  size = 'default',
  viewBox = '0 0 20 20',
  cx = '10',
  cy = '10',
  r = '5',
}) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle
      cx={cx}
      cy={cy}
      r={r}
      fill={color}
      stroke="#191C20"
      strokeWidth="2"
    />
  </svg>
);

export default Dot;
