import { HTMLAttributes, forwardRef, useMemo, useRef } from 'react';

import { ArgoIcon, MLFlowIcon } from '@pxui/components/ui/icons';
import { cn } from '@pxui/lib/utils';

import { Ellipse } from './Ellipse';
import { Planet, PlanetProps } from './Planet';
import { Vial } from './Vial';

interface EmptyStateProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  small?: boolean;
}

export const EmptyState = forwardRef<HTMLDivElement, EmptyStateProps>(
  ({ children, small, className }, ref) => {
    const textRef = useRef<HTMLDivElement>(null);

    const ellipses = [
      { color: '#27292E', size: 128 },
      { color: '#24262B', size: 180 },
      { color: '#202228', size: 232 },
      { color: '#1D1F24', size: 284 },
      { color: '#1D1F24', size: 336 },
    ];

    const icons: PlanetProps[] = useMemo(
      () => [
        { distance: 150, icon: ArgoIcon, id: 1, size: 'large' },
        {
          delay: true,
          direction: 'right',
          distance: 150,
          icon: MLFlowIcon,
          id: 2,
          size: 'large',
        },
        {
          delay: true,
          direction: 'right',
          distance: 225,
          icon: ArgoIcon,
          id: 3,
          size: 'small',
        },
        { delay: true, distance: 225, icon: MLFlowIcon, id: 4, size: 'small' },
      ],
      [],
    );

    return (
      <div
        ref={ref}
        className={cn(
          'w-full text-primary border border-dashed rounded-lg border-subtle bg-surface-00 flex justify-center items-center flex-col min-h-[calc(100vh-150px)]',
          className,
          {
            'overflow-hidden h-[272px] min-h-[initial]': small,
          },
        )}
        data-testid="empty-state-container"
      >
        <div
          className={cn(
            'relative h-full w-full flex justify-center items-center flex-col',
          )}
          style={{
            top: small ? '-20px' : 'initial',
          }}
        >
          {ellipses.map(({ size, color }) => (
            <Ellipse key={`${size}-${color}`} size={size} color={color} />
          ))}

          {icons.map(({ id, icon, distance, size, direction, delay }) => (
            <Planet
              key={id}
              icon={icon}
              distance={distance}
              size={size}
              direction={direction ?? 'left'}
              delay={delay ?? false}
            />
          ))}

          <div
            className={cn(
              'h-full w-full z-10 flex flex-col justify-center items-center',
              {
                'top-[110px]': small,
              },
            )}
          >
            <Vial />
            <div
              ref={textRef}
              className="mt-3 max-w-[200px] flex flex-col gap-1 bg-surface-00 bg-opacity-90 rounded-lg"
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default EmptyState;
