const Unchecked = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      className="stroke-icon-primary"
      d="M1.42455 13.5C1.15474 13.5 0.945851 13.4125 0.766688 13.2333C0.587524 13.0541 0.5 12.8453 0.5 12.5755V1.42455C0.5 1.15474 0.587524 0.945852 0.766687 0.766688C0.945851 0.587524 1.15474 0.5 1.42455 0.5H12.5754C12.8453 0.5 13.0541 0.587525 13.2333 0.766688C13.4125 0.945852 13.5 1.15474 13.5 1.42455V12.5755C13.5 12.8453 13.4125 13.0541 13.2333 13.2333C13.0541 13.4125 12.8453 13.5 12.5754 13.5H1.42455Z"
    />
  </svg>
);

export default Unchecked;
