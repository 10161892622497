import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Description: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      d="M7.20841 14.7916H12.7917V13.7084H7.20841V14.7916ZM7.20841 11.7916H12.7917V10.7084H7.20841V11.7916ZM5.75058 17.5833C5.37525 17.5833 5.05911 17.4538 4.80216 17.1948C4.54522 16.9357 4.41675 16.6187 4.41675 16.2436V3.75644C4.41675 3.38133 4.54626 3.06426 4.80529 2.80523C5.06432 2.5462 5.38139 2.41669 5.7565 2.41669H12.2084L15.5834 5.79165V16.2436C15.5834 16.6187 15.4538 16.9357 15.1946 17.1948C14.9354 17.4538 14.6181 17.5833 14.2428 17.5833H5.75058ZM11.4167 6.58331V3.5H5.7565C5.69239 3.5 5.63362 3.52671 5.58019 3.58013C5.52677 3.63356 5.50006 3.69233 5.50006 3.75644V16.2436C5.50006 16.3077 5.52677 16.3664 5.58019 16.4199C5.63362 16.4733 5.69239 16.5 5.7565 16.5H14.2436C14.3077 16.5 14.3665 16.4733 14.4199 16.4199C14.4734 16.3664 14.5001 16.3077 14.5001 16.2436V6.58331H11.4167Z"
      fill="currentColor"
    />
  </svg>
);

export default Description;
