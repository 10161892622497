import type { FC, LabelHTMLAttributes } from 'react';

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  text?: string;
}

const Label: FC<LabelProps> = ({ text, htmlFor, ...props }) => {
  if (!text) return null;

  return (
    <label htmlFor={htmlFor} {...props}>
      {text}
    </label>
  );
};

export { Label, type LabelProps };
