import { Link } from 'react-router-dom';

export const isBooleanString = (str: string): boolean => {
  return str === 'true' || str === 'false';
};

// Helper function to check if string is valid JSON
export const isJson = (str: string): boolean => {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};

export interface LinkObjType {
  external?: boolean;
  label: string;
  to: string;
}
/**
 * Inserts a link component at a specified position in a given text string.
 * @param {string} text - The base text string where the link will be injected.
 * @param {LinkObjType} linkObject - The link object wit
 * @param {number} index - The position in the text where the link should be inserted.
 * @returns {Array} - Array containing text nodes and the link component.
 */

export const injectLinkAtPosition = (
  text: string,
  link: LinkObjType,
  index: number,
) => {
  // Split the text around the specified index
  const beforeText = text.slice(0, index);
  const afterText = text.slice(index).replace(link.label, '');

  return (
    <>
      {beforeText}
      {link.external ? (
        <a
          href={link.to}
          target="_blank"
          rel="noreferrer"
          className="text-link-primary pl-1 label-1 hover:text-link-primary-hover"
        >
          {link.label}
        </a>
      ) : (
        <Link
          to={link.to}
          target="_blank"
          rel="noreferrer"
          className="text-link-primary pl-1 label-1 hover:text-link-primary-hover"
        >
          {link.label}
        </Link>
      )}
      {afterText}
    </>
  );
};
