import type { FC, HTMLAttributes } from 'react';

import { cn } from '@pxui/lib/utils';

const layoutClasses =
  'h-full w-[270px] flex flex-col items-start shrink-0 overflow-y-auto';
const colorClasses = 'surface-0';
const spacingClasses = 'gap-4 p-4';

export const List: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <div
    className={cn(layoutClasses, colorClasses, spacingClasses, className)}
    {...props}
  >
    {children}
  </div>
);

export default List;
