import type { FC } from 'react';

interface TreeViewSpacerProps {
  level?: number;
}

const SPACER_LEVEL_WIDTH = 28;

const TreeViewSpacer: FC<TreeViewSpacerProps> = ({ level = 0 }) => (
  <div
    style={{ width: SPACER_LEVEL_WIDTH * level }}
    className="h-full flex-shrink-0"
  />
);

export { TreeViewSpacer, type TreeViewSpacerProps };
