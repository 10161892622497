import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const DeleteForever: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.33317 12.9406L9.99984 11.274L11.6665 12.9406L12.4405 12.1667L10.7738 10.5L12.4405 8.83333L11.6665 8.05937L9.99984 9.72603L8.33317 8.05937L7.55921 8.83333L9.22588 10.5L7.55921 12.1667L8.33317 12.9406ZM6.7563 16.5833C6.38116 16.5833 6.06407 16.4538 5.80505 16.1948C5.54602 15.9358 5.4165 15.6187 5.4165 15.2435V5.49999H4.4165V4.41666H7.99984V3.51291H11.9998V4.41666H15.5832V5.49999H14.5832V15.2346C14.5832 15.6199 14.4537 15.941 14.1946 16.1979C13.9356 16.4549 13.6185 16.5833 13.2434 16.5833H6.7563ZM13.4998 5.49999H6.49984V15.2435C6.49984 15.3077 6.52657 15.3665 6.58005 15.4198C6.63338 15.4733 6.69213 15.5 6.7563 15.5H13.2434C13.3075 15.5 13.3663 15.4733 13.4196 15.4198C13.4731 15.3665 13.4998 15.3077 13.4998 15.2435V5.49999Z"
      fill="currentColor"
    />
  </svg>
);

export default DeleteForever;
