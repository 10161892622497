import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
} from 'react';

import { cn } from '@pxui/lib/utils';
import { Description } from '@radix-ui/react-toast';

export const ToastDescription = forwardRef<
  ElementRef<typeof Description>,
  ComponentPropsWithoutRef<typeof Description>
>(({ className, ...props }, ref) => (
  <Description
    ref={ref}
    className={cn('text-sm opacity-90 text-secondary', className)}
    {...props}
  />
));

ToastDescription.displayName = Description.displayName;

export default ToastDescription;
