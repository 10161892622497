import React from 'react';

import { cn } from '@pxui/lib/utils';
import { Content } from '@radix-ui/react-dialog';

import { DialogOverlay } from './dialogOverlay';
import { DialogPortal } from './dialogPortal';

const layoutClasses =
  'fixed left-[50%] top-[50%] z-50 grid translate-x-[-50%] translate-y-[-50%] rounded-xl';

const spacingClasses = 'p-6 gap-4';

const sizingClasses = 'max-w-[280px] max-h-[300px]';

const dataStateOpenClasses =
  'data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95 data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]';

const dataStateClosedClasses =
  'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%]';

export const DialogContent = React.forwardRef<
  React.ElementRef<typeof Content>,
  React.ComponentPropsWithoutRef<typeof Content>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <Content
      ref={ref}
      className={cn(
        'text-primary elevation-01 surface-01',
        layoutClasses,
        spacingClasses,
        sizingClasses,
        dataStateOpenClasses,
        dataStateClosedClasses,
        className,
      )}
      {...props}
    >
      {children}
    </Content>
  </DialogPortal>
));

DialogContent.displayName = Content.displayName;

export default DialogContent;
