import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const MoreHoriz: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.68776 11.0833C5.38864 11.0833 5.13404 10.9768 4.92397 10.7638C4.71391 10.5508 4.60889 10.2947 4.60889 9.99558C4.60889 9.69646 4.71539 9.44186 4.92841 9.23179C5.14142 9.02172 5.39749 8.91669 5.69662 8.91669C5.99574 8.91669 6.25033 9.02319 6.46039 9.23621C6.67046 9.44922 6.77549 9.70529 6.77549 10.0044C6.77549 10.3035 6.66898 10.5581 6.45597 10.7682C6.24296 10.9783 5.98689 11.0833 5.68776 11.0833ZM9.99545 11.0833C9.69632 11.0833 9.44173 10.9768 9.23166 10.7638C9.02159 10.5508 8.91655 10.2947 8.91655 9.99558C8.91655 9.69646 9.02306 9.44186 9.23607 9.23179C9.44909 9.02172 9.70516 8.91669 10.0043 8.91669C10.3034 8.91669 10.558 9.02319 10.7681 9.23621C10.9781 9.44922 11.0832 9.70529 11.0832 10.0044C11.0832 10.3035 10.9767 10.5581 10.7637 10.7682C10.5506 10.9783 10.2946 11.0833 9.99545 11.0833ZM14.3031 11.0833C14.004 11.0833 13.7494 10.9768 13.5393 10.7638C13.3293 10.5508 13.2242 10.2947 13.2242 9.99558C13.2242 9.69646 13.3307 9.44186 13.5438 9.23179C13.7568 9.02172 14.0128 8.91669 14.312 8.91669C14.6111 8.91669 14.8657 9.02319 15.0758 9.23621C15.2858 9.44922 15.3908 9.70529 15.3908 10.0044C15.3908 10.3035 15.2843 10.5581 15.0713 10.7682C14.8583 10.9783 14.6022 11.0833 14.3031 11.0833Z"
      fill="currentColor"
    />
  </svg>
);

export default MoreHoriz;
