import { type ThHTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

const layoutClasses = 'text-left align-middle label-1';
const colorClasses = 'text-secondary';
const spacingClasses = 'px-4 py-[7px]';

export const TableHead = forwardRef<
  HTMLTableCellElement,
  ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(layoutClasses, colorClasses, spacingClasses, className)}
    {...props}
  />
));

TableHead.displayName = 'TableHead';

export default TableHead;
